//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import { bindActionCreators } from 'redux';

import { getPathForOverlayKey } from '@components/OverlayManager';
import ApiConfiguration         from '@constants/Api';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import Base64                   from '@helper/Base64';
import * as Configuration       from '@helper/Configuration';
import Connection               from '@helper/Connection';
import Environment              from '@helper/Environment';
import FormPoster               from '@helper/FormPoster';
import StateHelper              from '@helper/State';
import { ActiveProjectActions } from '@slices/activeProject';

import ActionGroup     from '../ActionGroup';
import ActionGroupItem from '../ActionGroupItem';
import IconType        from '../Icon/IconType';

class Component extends React.Component {
    deleteModulesButtonPressed = () => {
        this.props.history.push(getPathForOverlayKey(Overlays.deleteModules));
    };

    downloadImageButtonPressed = () => {
        this.postImageForm(true);
    };

    duplicateButtonPressed = () => {
        this.props.duplicateSelectedProduct({
            confirmed: false,
        });
    };

    downloadImageBackendButtonPressed = () => {
        this.postImageForm();
    };

    getPngRendererUrl(download) {
        const finalUrl = `${ApiConfiguration.getBackendUrl()}/png-renderer?cardOverlayMode=${this.props.cardOverlayMode
        }${download
            ? '&download=1&hideIcons=true'
            : ''}&cardOverlayModeRenderExtenderId=${
            this.props.cardOverlayModeRenderExtenderId ?
                'true' :
                'false'}`;

        return finalUrl;
    }

    postImageForm(download) {
        if (Connection.isOnline()) {
            const finalUrl            = this.getPngRendererUrl(download);
            const selectedProductJson = JSON.stringify(this.props.selectedProduct);
            const encodedString       = Base64.encode(selectedProductJson);

            FormPoster.openFormWindowPost(
                finalUrl,
                'chassisPayload',
                encodedString,
            );
        } else {
            this.props.history.push(getPathForOverlayKey(Overlays.noInternetConnection));
        }
    }

    reverseLayoutButtonPressed = () => {
        this.props.reverseLayoutedProduct();
    };

    render() {
        return (
            <ActionGroup>
                {this.renderDeleteModulesButton()}
                {this.renderReverseLayoutButton()}
                {this.renderDuplicateButton()}
                {this.renderDownloadImageButton()}
                {this.renderDownloadImageBackendButton()}
            </ActionGroup>
        );
    }

    renderDeleteModulesButton = () => {
        return (
            <ActionGroupItem
                iconType={IconType.trashBin}
                onClick={this.deleteModulesButtonPressed}
                text={I18n.t('deleteModules')}
                tooltipText={I18n.t('deleteModulesTip')}
            />
        );
    };

    renderDownloadImageButton = () => {
        return (
            <ActionGroupItem
                iconType={IconType.import2}
                onClick={this.downloadImageButtonPressed}
                text={I18n.t('downloadImage')}
                tooltipText={I18n.t('downloadImageTip')}
            />
        );
    };

    renderDownloadImageBackendButton = () => {
        const isDevelopment = Environment.isDevelopment();
        const isStaging     = Environment.isStaging();

        if (isDevelopment || isStaging) {
            return (
                <ActionGroupItem
                    iconType={IconType.import2}
                    onClick={this.downloadImageBackendButtonPressed}
                    text={I18n.t('downloadImageBackend')}
                    tooltipText={I18n.t('downloadImageBackendTip')}
                />
            );
        }

        return null;
    };

    renderDuplicateButton = () => {
        return (
            <ActionGroupItem
                iconType={IconType.duplicate}
                onClick={this.duplicateButtonPressed}
                text={I18n.t('duplicateExtender')}
                tooltipText={I18n.t('duplicateExtenderTip')}
            />
        );
    };

    renderReverseLayoutButton = () => {
        if (!Configuration.isReverseLayoutFeatureEnabled()) {
            return null;
        }

        console.log('props.selectedProduct.productData.mutable', this.props.selectedProduct);

        if (
            _.get(this, 'props.selectedProduct.categoryType') === ProductCategoryType.extender &&
            _.get(this, 'props.selectedProduct.productData.mutable', false)
        ) {
            return (
                <ActionGroupItem
                    iconType={IconType.reverse}
                    onClick={this.reverseLayoutButtonPressed}
                    text={I18n.t('reverseLayout')}
                    tooltipText={I18n.t('reverseLayoutTip')}
                />
            );
        }

        return null;
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        cardOverlayMode:                 state.settings.cardOverlayMode,
        cardOverlayModeRenderExtenderId: state.settings.cardOverlayModeRenderExtenderId,
        selectedProduct:                 StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(ActiveProjectActions), dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component)));
