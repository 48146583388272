//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { withTranslation } from 'react-i18next';

import MainboardTypes  from '@constants/MainboardTypes';
import SelectionHelper from '@helper/SelectionHelper';

import MainboardTypeLabelAlignment from './MainboardTypeLabelAlignment';
import styles                      from './styles.module.scss';
import ProductDataValueLabel       from '../ProductDataValueLabel';
import PropTypes                   from '../PropTypes';

const Component = ({
    alignment,
    mainboardType,
    value,
    t,
}) => {
    const getTitle = () => {
        return SelectionHelper.getTranslation(
            mainboardType,
            {
                [MainboardTypes.ultra]:   'ultra',
                [MainboardTypes.classic]: 'classic',
                [MainboardTypes.xs]:      'xs',
            },
        );
    };

    if (mainboardType) {
        const title = getTitle();

        if (!title) {
            console.warn('Unsupported mainboard type', mainboardType);
        }

        return (
            <span
                className={classNames(
                    styles.mainboardTypeLabel,
                    SelectionHelper.get(
                        alignment,
                        {
                            [MainboardTypeLabelAlignment.left]:  styles.mainboardTypeLabelLeft,
                            [MainboardTypeLabelAlignment.right]: styles.mainboardTypeLabelRight,
                        },
                    ),
                )}
                title={title}
            >
                <span className={styles.mainboardTypeText}>
                    {title}
                </span>
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );
};

Component.propTypes = {
    alignment:     PropTypes.oneOf(Object.values(MainboardTypeLabelAlignment)),
    mainboardType: PropTypes.string,
};

Component.defaultProps = {
    alignment:     null,
    mainboardType: null,
};

export default withTranslation()(Component);
