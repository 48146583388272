//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames             from 'classnames';

import { ProjectsActions } from '@slices/projects';
import ProjectTitleHelper  from '@helper/ProjectTitle';

import styles        from './styles.module.scss';
import FormattedDate from '../FormattedDate';
import Icon          from '../Icon';
import IconType      from '../Icon/IconType';
import PropTypes     from '../PropTypes';

const Component = ({
    project,
    deleteProject,
    loadProject,
}) => {
    const deleteProjectButtonPressed = (event) => {
        deleteProject({
            id: project.id,
        });

        event.preventDefault();
        event.stopPropagation();
    };
    const getNameOfResponsiblePerson = () => {
        const fallback = I18n.t('nameOfResponsiblePersonFallbackName');

        return (
            _.get(
                project,
                'metaData.nameOfResponsiblePerson',
                fallback,
            )
            // We have to do this since _.get allows empty strings
            || fallback
        );
    };
    const getProjectTitle            = () => {
        return ProjectTitleHelper.getProjectTitle(_.get(project, 'metaData.title'));
    };
    const projectSelected            = () => {
        loadProject({
            projectData: project,
        });
    };

    if (project) {
        return (
            <li
                className={styles.projectListItem}
                onClick={projectSelected}
            >
                <h3 className={styles.projectListItemTitle}>
                    {getProjectTitle()}
                </h3>
                <dl className={styles.projectListItemDetailList}>
                    <dt className={styles.projectListItemDetailListTitle}>
                        {I18n.t('nameOfResponsiblePerson')}:
                    </dt>
                    <dd className={styles.projectListItemDetailListValue}>
                        {getNameOfResponsiblePerson()}
                    </dd>
                    <dt className={styles.projectListItemDetailListTitle}>
                        {I18n.t('lastUpdate')}:
                    </dt>
                    <dd
                        className={classNames(
                            styles.projectListItemDetailListValue,
                            styles.projectListItemDetailListValueDate,
                        )}
                    >
                        <FormattedDate
                            date={project.lastUpdateDate}
                            format={I18n.t('lastUpdateFormat')}
                            postfix={I18n.t('timeOClock')}
                        />
                    </dd>
                </dl>
                {renderDeleteButton()}
                <div className={styles.projectListItemCallToAction}>
                    <Icon iconType={IconType.chevronRight} />
                </div>
            </li>
        );
    }

    return null;

    function renderDeleteButton() {
        return (
            <a
                className={styles.projectListItemDeleteButton}
                onClick={deleteProjectButtonPressed}
                title={I18n.t('deleteProject')}
            >
                <Icon iconType={IconType.trashBin} />
            </a>
        );
    }
};

Component.propTypes = {
    deleteProject: PropTypes.func.isRequired,
    loadProject:   PropTypes.func.isRequired,
    project:       PropTypes.object,
};

Component.defaultProps = {
    project: null,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(ProjectsActions), dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(Component));
