//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import KeyboardEventHandler   from 'react-keyboard-event-handler';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import DropDown                 from '@components/DropDown';
import DropDownButton           from '@components/DropDownButton';
import HeaderButton             from '@components/HeaderButton';
import HeaderDropZone           from '@components/HeaderDropZone';
import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';
import Routes                   from '@constants/Routes';
import TestIds                  from '@constants/TestIds';
import * as Configuration       from '@helper/Configuration';
import GoogleAnalytics          from '@helper/GoogleAnalytics';
import KeyHandler               from '@helper/KeyHandler';
import { ActiveProjectActions } from '@slices/activeProject';
import { DebugActions }         from '@slices/debug';

import HeaderMode            from './HeaderMode';
import styles                from './styles.module.scss';
import DropDownArrowPosition from '../DropDown/DropDownArrowPosition';
import DropDownTheme         from '../DropDown/DropDownTheme';
import HeaderButtonTheme     from '../HeaderButton/HeaderButtonTheme';
import IconType              from '../Icon/IconType';
import Link                  from '../Link';
import PropTypes             from '../PropTypes';
import SplitPaneManager      from '../SplitPaneManager';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            debugKeyPressed: false,
        };
    }

    altKeyDown = (key, event) => {
        const keyHandler = KeyHandler.getInstance();

        keyHandler.setAltKeyPressed(true);
    };

    altKeyUp = (key, event) => {
        const keyHandler = KeyHandler.getInstance();

        keyHandler.setAltKeyPressed(false);
    };

    closeProjectButtonPressed = () => {
        this.props.closeProject({
            closeConfirmed:  true,
            routeAfterClose: Routes.home,
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.locationPathName !== nextProps.locationPathName) {
            // This will track every page change
            GoogleAnalytics.trackPageView();
        }
    }

    isDesignerPage = () => {
        return this.props.locationPathName.startsWith(Routes.designer);
    };

    debugKeyPressed = (key, event) => {
        this.setState({
            debugKeyVisible: !this.state.debugKeyVisible,
        });
    };

    getDropDownTheme() {
        if (this.isDesignerPage()) {
            return DropDownTheme.orange;
        }

        return DropDownTheme.black;
    }

    render() {
        return (
            <div className={styles.headerContainer}>
                <HeaderDropZone />
                <div className={styles.headerContainerLeft}>
                    {this.renderLeft()}
                </div>
                <div className={styles.headerContainerCenter}>
                    {this.renderCenter()}
                </div>
                <div className={styles.headerContainerRight}>
                    {this.renderRight()}
                </div>
            </div>
        );
    }

    renderCenter = () => {
        switch (this.props.mode) {
            // @formatter:off
            case HeaderMode.designer:           return this.renderCenterDesigner();
            case HeaderMode.smallScreenOverlay: return this.renderCenterSmallScreenOverlay();
            // @formatter:on
            case HeaderMode.default:
            default:
                return this.renderCenterDefault();
        }
    };

    renderCenterDefault = () => {
        return this.renderIhseLogo();
    };

    renderRequestAQuoteButton = () => {
        if (!Configuration.getShowRequestAQuoteButton()) {
            return null;
        }

        return (
            <HeaderButton
                testId={TestIds.headerButtonRequestQuote}
                key={'requestQuote'}
                icon={IconType.requestQuote}
                route={getPathForOverlayKey(Overlays.salesMailbox)}
                text={I18n.t('requestQuote')}
                shortText={I18n.t('requestQuoteShort')}
            />
        );
    };

    renderImportButton = () => {
        if (!Configuration.getShowImportButton()) {
            return null;
        }

        return (
            <HeaderButton
                testId={TestIds.headerButtonImport}
                key={'import'}
                icon={IconType.import}
                route={getPathForOverlayKey(Overlays.importActiveProject)}
                text={I18n.t('import')}
            />
        );
    };

    renderExportButton = () => {
        if (!Configuration.getShowExportButton()) {
            return null;
        }

        return (
            <HeaderButton
                testId={TestIds.headerButtonExport}
                key={'export'}
                icon={IconType.export}
                route={getPathForOverlayKey(Overlays.export)}
                text={I18n.t('export')}
            />
        );
    };

    renderPartsListButton = () => {
        if (!Configuration.getShowPartsListButton()) {
            return null;
        }

        return (
            <HeaderButton
                testId={TestIds.headerButtonPartsList}
                key={'partsList'}
                icon={IconType.partList}
                route={getPathForOverlayKey(Overlays.partsList)}
                text={I18n.t('partsList')}
            />
        );
    };

    renderPrintButton = () => {
        if (!Configuration.getShowPrintButton()) {
            return null;
        }

        return (
            <HeaderButton
                testId={TestIds.headerButtonPrint}
                key={'print'}
                icon={IconType.print}
                route={getPathForOverlayKey(Overlays.printPreview)}
                text={I18n.t('print')}
            />
        );
    };

    renderDataProtectionButton = () => {
        if (!Configuration.getShowDataProtectionButton()) {
            return null;
        }

        return (
            <HeaderButton
                testId={TestIds.headerButtonDataProtection}
                key={'dataProtection'}
                icon={IconType.dataProtection}
                route={Configuration.getPrivacyPolicyUrl()}
                text={I18n.t('dataProtectionShort')}
                shortText={I18n.t('dataProtectionAbbreviation')}
            />
        );
    };

    renderCenterDesigner = () => {
        return [
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-759
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-761
            <KeyboardEventHandler
                key={'keyboardEventHandlerShiftAltDKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['shift+alt+d']}
                onKeyEvent={this.debugKeyPressed}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerAltKeyUp'}
                handleEventType={'keydown'}
                handleKeys={['alt']}
                onKeyEvent={this.altKeyDown}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerAltKeyDown'}
                handleEventType={'keyup'}
                handleKeys={['alt']}
                onKeyEvent={this.altKeyUp}
            />,
            (
                this.state.debugKeyVisible ?
                    (
                        <HeaderButton
                            dropDown={this.renderDebugDropDown(DropDownArrowPosition.left)}
                            key={'debug'}
                            icon={IconType.dots}
                            text={I18n.t('debug')}
                        />
                    )
                    : null
            ),
            this.renderRequestAQuoteButton(),
            this.renderImportButton(),
            this.renderExportButton(),
            this.renderPartsListButton(),
            this.renderPrintButton(),
            this.renderHeaderMoreButton(DropDownArrowPosition.center),
            this.renderDataProtectionButton(),
            <HeaderButton
                testId={TestIds.headerButtonCloseProject}
                key={'closeProject'}
                icon={IconType.close}
                onClick={this.closeProjectButtonPressed}
                text={I18n.t('closeProject')}
            />,
        ];
    };

    renderCenterSmallScreenOverlay = () => {
        return this.renderIhseLogo();
    };

    renderDebugDropDown = (arrowPosition) => {
        const theme = this.getDropDownTheme();

        return (
            <DropDown
                arrowPosition={arrowPosition}
                theme={theme}
            >
                <DropDownButton
                    onClick={this.resetAllButtonPressed}
                    text={I18n.t('resetAll')}
                />
            </DropDown>
        );
    };

    renderDracoSystemDesignerLogo = () => {
        return (
            <Link to={Routes.designer}>
                <h1 className={styles.headerContainerDracoSystemDesignerLogo}>
                    {I18n.t('appName')}
                </h1>
            </Link>
        );
    };

    renderHeaderMoreButton = (arrowPosition, theme) => {
        return (
            <HeaderButton
                dropDown={this.renderMoreDropDown(arrowPosition)}
                key={'more'}
                icon={IconType.dots}
                text={I18n.t('more')}
                theme={theme}
            />
        );
    };

    renderIhseLogo = () => {
        return (
            <Link to={Routes.home}>
                <h1 className={styles.headerContainerIhseLogo}>
                    {I18n.t('ihseCompanyName')}
                </h1>
            </Link>
        );
    };

    renderLeft = () => {
        switch (this.props.mode) {
            // @formatter:off
            case HeaderMode.designer:           return this.renderLeftDesigner();
            case HeaderMode.smallScreenOverlay: return this.renderLeftSmallScreenOverlay();
            // @formatter:on
            case HeaderMode.default:
            default:
                return this.renderLeftDefault();
        }
    };

    renderLeftDefault = () => {
        return null;
    };

    renderLeftDesigner = () => {
        return this.renderDracoSystemDesignerLogo();
    };

    renderMoreDropDown = (arrowPosition) => {
        const theme                       = this.getDropDownTheme();
        const renderImprintDropDownButton = () => {
            if (Configuration.getShowImprintButton()) {
                return (
                    <DropDownButton
                        route={Routes.imprint}
                        text={I18n.t('imprint')}
                    />
                );
            }
        };

        return (
            <DropDown
                arrowPosition={arrowPosition}
                theme={theme}
            >
                <DropDownButton
                    route={Routes.home}
                    text={I18n.t('homePage')}
                />
                <DropDownButton
                    route={getPathForOverlayKey(Overlays.settings)}
                    text={I18n.t('settings')}
                />
                <DropDownButton
                    route={Routes.help}
                    text={I18n.t('help')}
                />
                <DropDownButton
                    route={Routes.contact}
                    text={I18n.t('contact')}
                />
                {renderImprintDropDownButton()}
            </DropDown>
        );
    };

    renderLeftSmallScreenOverlay = () => {
        return null;
    };

    renderRight = () => {
        switch (this.props.mode) {
            // @formatter:off
            case HeaderMode.designer:           return this.renderRightDesigner();
            case HeaderMode.smallScreenOverlay: return this.renderRightSmallScreenOverlay();
            // @formatter:on
            case HeaderMode.default:
            default:
                return this.renderRightDefault();
        }
    };

    renderRightDefault = () => {
        return [
            <HeaderButton
                key={'dataProtection'}
                icon={IconType.dataProtection}
                route={Configuration.getPrivacyPolicyUrl()}
                text={I18n.t('dataProtectionShort')}
            />,
            this.renderHeaderMoreButton(DropDownArrowPosition.right, HeaderButtonTheme.leftMargin),
        ];
    };

    renderRightDesigner = () => {
        return (
            <SplitPaneManager />
        );
    };

    renderRightSmallScreenOverlay = () => {
        return null;
    };

    resetAllButtonPressed = () => {
        this.props.resetAll();
    };
}

Component.propTypes = {
    mode: PropTypes.oneOf(Object.values(HeaderMode)),
};

Component.defaultProps = {
    mode: HeaderMode.default,
};

const mapStateToProps = (state) => (
    {
        // This is required to force a re-render of the header when the location changes
        // since otherwise the routes generated with getPathForOverlayKey()
        // will generate wrong urls.
        locationPathName: state.router.location.pathname,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DebugActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));
