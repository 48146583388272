//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import Routes from '@constants/Routes';

import config from '../data/config.json';

export function getTabVisibility(type, name) {
    return _.get(config, ['tabVisibility', type, name], true);
}

export function getProductCategoryVisibility(type) {
    return _.get(config, ['productVisibility', type], true);
}

export function shouldRenderSalesMail() {
    return config.shouldRenderSalesMail;
}

export const getShowRequestAQuoteButton = () => {
    return config.showRequestAQuoteButton;
};

export const isReverseLayoutFeatureEnabled = () => {
    return config.enableReverseLayoutFeature;
};

export const getShowImportButton = () => {
    return config.showImportButton;
};

export const getShowExportButton = () => {
    return config.showExportButton;
};

export const getShowPartsListButton = () => {
    return config.showPartsListButton;
};

export const getShowPrintButton = () => {
    return config.showPrintButton;
};

export const getShowDataProtectionButton = () => {
    return config.showDataProtectionButton;
};

export const getMinimumTableColumnWidth = () => {
    return config.minimumTableColumnWidth || 400;
};

export function getExportFilename() {
    return config.exportFilename;
}

export function getCompanyName() {
    return config.companyName;
}

export function getSalesEmail() {
    return config.salesEmail;
}

export function getContactOtherLocationsLink() {
    return config.contactOtherLocationsLink;
}

export const isAbasExportEnabled = () => {
    return config.enableAbasExport || false;
};

export const isKVMTecEnabled = () => {
    return config.enableKVMTec || false;
};

export const isBigLogoImageEnabled = () => {
    return config.showBigLogoImage || false;
};

export const getTitleBannerLeftText = (fallback) => {
    return config.headerStrongText || fallback;
};

export const getTitleBannerRightText = (fallback) => {
    return config.headerText || fallback;
};

export const getTitleBannerSlogan = (fallback) => {
    return config.headerSlogan || fallback;
};

export const getVersion = () => {
    return config.version || 'Unknown';
};

export const getProductionBackendUrl = () => {
    return config.productionBackend;
};

export const getStagingBackendUrl = () => {
    return config.stagingBackend;
};

export const getApplicationName = () => {
    return config.applicationName;
};

export const getContact = (type) => {
    return _.get(config, ['contact', type], null);
};

export const getProductSubCategoryAlias = (name) => {
    return _.get(config, ['productSubCategoryAlias', name], '');
};

export const getPrivacyPolicyUrl = () => {
    return config.privacyPolicyUrl || Routes.dataProtection;
};

export const getShowImprintButton = () => {
    return config.showImprintButton;
};

export const getEnabledLanguageKeys = () => {
    return config.enabledLanguageKeys;
};

export const getReverseLayoutIndicatorPosition = () => {
    return config.reverseLayoutIndicatorPosition;
};

export const getReverseLayoutIndicatorCharacters = () => {
    return config.reverseLayoutIndicatorCharacters;
};
