//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import EquipmentProductBrowser      from '@components/EquipmentProductBrowser';
import ExtenderProductBrowser       from '@components/ExtenderProductBrowser';
import FullIpProductBrowser         from '@components/FullIpProductBrowser';
import MatrixProductBrowser         from '@components/MatrixProductBrowser';
import ProductCategorySelectionPane from '@components/ProductCategorySelectionPane';

import ProductCategoryType from '@constants/ProductCategoryType';
import * as Configuration  from '@helper/Configuration';
import SelectionHelper     from '@helper/SelectionHelper';

import styles from './styles.module.scss';

const getActiveProductCategoryName = (activeProductCategoryType) => {
    return SelectionHelper.getTranslation(activeProductCategoryType, {
        [ProductCategoryType.equipment]: 'equipment',
        [ProductCategoryType.extender]:  'extender',
        [ProductCategoryType.matrix]:    'matrixSwitch',
        [ProductCategoryType.fullIp]:    'fullIp',
    });
};

const renderProductBrowserWrapper = (activeProductCategoryType) => {
    if (!productCategoryVisible(activeProductCategoryType)) {
        return false;
    }

    return (
        <div className={styles.productBrowserManager}>
            <div className={styles.productBrowserManagerActiveProductCategoryIndicator}> {I18n.t('selection')}
                : {getActiveProductCategoryName(activeProductCategoryType)}
            </div>
            {
                renderProductBrowserForActiveProductCategory(activeProductCategoryType)
            }
        </div>
    );
};

const renderEmptyText = () => {
    return (
        <ProductCategorySelectionPane />
    );
};

const renderExtenderProductBrowser = () => {
    return (
        <ExtenderProductBrowser />
    );
};

const renderEquipmentProductBrowser = () => {
    return (
        <EquipmentProductBrowser />
    );
};

const renderMatrixProductBrowser = () => {
    return (
        <MatrixProductBrowser />
    );
};

const renderFullIpProductBrowser = () => {
    return (
        <FullIpProductBrowser />
    );
};

const renderProductBrowserForActiveProductCategory = (activeProductCategoryType) => {
    return SelectionHelper.get(
        activeProductCategoryType,
        {
            [ProductCategoryType.equipment]: renderEquipmentProductBrowser(),
            [ProductCategoryType.extender]:  renderExtenderProductBrowser(),
            [ProductCategoryType.matrix]:    renderMatrixProductBrowser(),
            [ProductCategoryType.fullIp]:    renderFullIpProductBrowser(),
        },
    );
};

const ProductBrowserManager = ({
    activeProductCategoryType,
}) => {
    if (activeProductCategoryType) {
        return renderProductBrowserWrapper(activeProductCategoryType);
    }

    return renderEmptyText();
};

ProductBrowserManager.propTypes = {};

ProductBrowserManager.defaultProps = {};

const mapStateToProps = (state) => (
    {
        activeProductCategoryType: state.activeProject.activeProductCategoryType,
    }
);

export const productCategoryVisible = (activeProductCategoryType) => {
    return SelectionHelper.get(
        activeProductCategoryType,
        {
            [ProductCategoryType.extender]:  Configuration.getProductCategoryVisibility(ProductCategoryType.extender),
            [ProductCategoryType.matrix]:    Configuration.getProductCategoryVisibility(ProductCategoryType.matrix),
            [ProductCategoryType.equipment]: Configuration.getProductCategoryVisibility(ProductCategoryType.equipment),
            [ProductCategoryType.fullIp]:    Configuration.isKVMTecEnabled(),
        },
        false,
    );
};

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(ProductBrowserManager));
