//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

/* eslint-disable global-require */
const productImages = {
    '238-BNC-5x5': require('../assets/images/products/238-bnc-5x5.png'),
    '247-U1': require('../assets/images/products/247-u1.png'),
    '247-U2': require('../assets/images/products/247-u2.png'),
    '436-AA': require('../assets/images/products/436-aa.png'),
    '436-DH59': require('../assets/images/products/436-dh59.png'),
    '436-DL59': require('../assets/images/products/436-dl59.png'),
    '436-DPDV': require('../assets/images/products/436-dpdv.png'),
    '436-ID': require('../assets/images/products/436-id.png'),
    '436-PU': require('../assets/images/products/436-pu.png'),
    '436-UP': require('../assets/images/products/436-up.png'),
    '436-USB2.0-2M': require('../assets/images/products/436-usb2.0-2m.png'),
    '444-K25': require('../assets/images/products/444-k25.png'),
    '444-K25P': require('../assets/images/products/444-k25p.png'),
    '444-KDE': require('../assets/images/products/444-kde.png'),
    '444-KUS': require('../assets/images/products/444-kus.png'),
    '444-P2U': require('../assets/images/products/444-p2u.png'),
    '444-U2P': require('../assets/images/products/444-u2p.png'),
    '444-UBR': require('../assets/images/products/444-ubr.png'),
    '445-2H': require('../assets/images/products/445-2h.png'),
    '459-10S10': require('../assets/images/products/459-10s10.png'),
    '459-1M': require('../assets/images/products/459-1m.png'),
    '459-1S-SRX': require('../assets/images/products/459-1s-srx.png'),
    '459-1S-STX': require('../assets/images/products/459-1s-stx.png'),
    '459-2BX': require('../assets/images/products/459-2bx.png'),
    '459-H2S': require('../assets/images/products/459-h2s.png'),
    '459-S2H': require('../assets/images/products/459-s2h.png'),
    '470-1S-1927': require('../assets/images/products/470-1s-1927.png'),
    '470-1S-1929': require('../assets/images/products/470-1s-1929.png'),
    '470-1S-1931': require('../assets/images/products/470-1s-1931.png'),
    '470-1S-1933': require('../assets/images/products/470-1s-1933.png'),
    '470-1S-1935': require('../assets/images/products/470-1s-1935.png'),
    '470-1S-1937': require('../assets/images/products/470-1s-1937.png'),
    '470-1S-1939': require('../assets/images/products/470-1s-1939.png'),
    '470-1S-1941': require('../assets/images/products/470-1s-1941.png'),
    '470-1S-1943': require('../assets/images/products/470-1s-1943.png'),
    '470-1S-1945': require('../assets/images/products/470-1s-1945.png'),
    '470-1S-1947': require('../assets/images/products/470-1s-1947.png'),
    '470-1S-1949': require('../assets/images/products/470-1s-1949.png'),
    '470-1S-1951': require('../assets/images/products/470-1s-1951.png'),
    '470-1S-1953': require('../assets/images/products/470-1s-1953.png'),
    '470-1S-1955': require('../assets/images/products/470-1s-1955.png'),
    '470-1S-1957': require('../assets/images/products/470-1s-1957.png'),
    '470-1S-1959': require('../assets/images/products/470-1s-1959.png'),
    '470-1S-1961': require('../assets/images/products/470-1s-1961.png'),
    '470-1X-1347': require('../assets/images/products/470-1x-1347.png'),
    '470-1X-1349': require('../assets/images/products/470-1x-1349.png'),
    '470-1X-1351': require('../assets/images/products/470-1x-1351.png'),
    '470-1X-1353': require('../assets/images/products/470-1x-1353.png'),
    '470-1X-1355': require('../assets/images/products/470-1x-1355.png'),
    '470-1X-1357': require('../assets/images/products/470-1x-1357.png'),
    '470-1X-1359': require('../assets/images/products/470-1x-1359.png'),
    '470-1X-1361': require('../assets/images/products/470-1x-1361.png'),
    '474-CTRLPAD24': require('../assets/images/products/474-ctrlpad24.png'),
    '474-CTRLPAD48': require('../assets/images/products/474-ctrlpad48.png'),
    '474-KEY04': require('../assets/images/products/474-key04.png'),
    '474-KEY08': require('../assets/images/products/474-key08.png'),
    '474-KEY16': require('../assets/images/products/474-key16.png'),
    '474-VRMK': require('../assets/images/products/474-vrmk.png'),
    '476-CTRL4': require('../assets/images/products/476-ctrl4.png'),
    '476-CTRL4-GPIO': require('../assets/images/products/476-ctrl4-gpio.png'),
    '476-CTRL8': require('../assets/images/products/476-ctrl8.png'),
    '477-KVM-R1': require('../assets/images/products/477-kvm-r1.png'),
    '480-VIEW': require('../assets/images/products/480-view.png'),
    '481-H2S': require('../assets/images/products/481-h2s.png'),
    '481-H3S': require('../assets/images/products/481-h3s.png'),
    '481-H5S': require('../assets/images/products/481-h5s.png'),
    'K238-5FBNC': require('../assets/images/products/k238-5fbnc.png'),
    'K238-5V': require('../assets/images/products/k238-5v.png'),
    'K238-5VE': require('../assets/images/products/k238-5ve.png'),
    'K461-1W': require('../assets/images/products/k461-1w.png'),
    'K461-2U': require('../assets/images/products/k461-2u.png'),
    'K461-DU': require('../assets/images/products/k461-du.png'),
    'K461-SU': require('../assets/images/products/k461-su.png'),
    'K463-1U': require('../assets/images/products/k463-1u.png'),
    'K463-1W': require('../assets/images/products/k463-1w.png'),
    'K467-0S': require('../assets/images/products/k467-0s.png'),
    'K469-DV': require('../assets/images/products/k469-dv.png'),
    'K470-CW18': require('../assets/images/products/k470-cw18.png'),
    'K470-CW18-SFP19-1G': require('../assets/images/products/k470-cw18-sfp19-1g.png'),
    'K470-CW4': require('../assets/images/products/k470-cw4.png'),
    'K470-CW8-SFP19-1G': require('../assets/images/products/k470-cw8-sfp19-1g.png'),
    'K475-XP': require('../assets/images/products/k475-xp.png'),
    'K476-4U': require('../assets/images/products/k476-4u.png'),
    'K476-4U2': require('../assets/images/products/k476-4u2.png'),
    'K476-4U4T': require('../assets/images/products/k476-4u4t.png'),
    'K476-8U': require('../assets/images/products/k476-8u.png'),
    'K488-UST': require('../assets/images/products/k488-ust.png'),
    'MV42-DPDH': require('../assets/images/products/mv42-dpdh.png'),
    'MV42-DPSH': require('../assets/images/products/mv42-dpsh.png'),
    'MV42-H2DH': require('../assets/images/products/mv42-h2dh.png'),
    'MV42-H2SH': require('../assets/images/products/mv42-h2sh.png'),
    '474-BODY2': require('../assets/images/products/474-body2.png'),
    '474-BODY2BPF': require('../assets/images/products/474-body2bpf.png'),
    '474-BODY2BPF-MAR': require('../assets/images/products/474-body2bpf-mar.png'),
    '474-BODY2BPF-S': require('../assets/images/products/474-body2bpf-s.png'),
    '474-BODY2BPF-SNMP': require('../assets/images/products/474-body2bpf-snmp.png'),
    '474-BODY2DC-12': require('../assets/images/products/474-body2dc-12.png'),
    '474-BODY2DC-24': require('../assets/images/products/474-body2dc-24.png'),
    '474-BODY2DC-48': require('../assets/images/products/474-body2dc-48.png'),
    '474-BODY2N': require('../assets/images/products/474-body2n.png'),
    '474-BODY2N-MAR': require('../assets/images/products/474-body2n-mar.png'),
    '474-BODY2R': require('../assets/images/products/474-body2r.png'),
    '474-BODY4': require('../assets/images/products/474-body4.png'),
    '474-BODY4R': require('../assets/images/products/474-body4r.png'),
    '474-BODY6BP': require('../assets/images/products/474-body6bp.png'),
    '474-BODY6BP-MAR': require('../assets/images/products/474-body6bp-mar.png'),
    '474-BODY6BP-S': require('../assets/images/products/474-body6bp-s.png'),
    '474-BODY6BP-SNMP': require('../assets/images/products/474-body6bp-snmp.png'),
    '474-BODY6BPF': require('../assets/images/products/474-body6bpf.png'),
    '474-BODY6BPF-S': require('../assets/images/products/474-body6bpf-s.png'),
    '474-BODY6DC-12': require('../assets/images/products/474-body6dc-12.png'),
    '474-BODY6DC-24': require('../assets/images/products/474-body6dc-24.png'),
    '474-BODY6DC-48': require('../assets/images/products/474-body6dc-48.png'),
    '474-BODY6R-R1': require('../assets/images/products/474-body6r-r1.png'),
    '474-BODY6R-R1-MAR': require('../assets/images/products/474-body6r-r1-mar.png'),
    '474-BODY21/4U': require('../assets/images/products/474-body21-4u.png'),
    '474-BODY21/4U-R1': require('../assets/images/products/474-body21-4u-r1.png'),
    '474-BODY21/4UR': require('../assets/images/products/474-body21-4ur.png'),
    '474-BODY21/4UR-R1': require('../assets/images/products/474-body21-4ur-r1.png'),
    '260-5M': require('../assets/images/products/260-5m.png'),
    '474-2NRMKL': require('../assets/images/products/474-2nrmkl.png'),
    '474-2NRMKR': require('../assets/images/products/474-2nrmkr.png'),
    '474-2RMKL': require('../assets/images/products/474-2rmkl.png'),
    '474-2RMKR': require('../assets/images/products/474-2rmkr.png'),
    '474-4RMKL': require('../assets/images/products/474-4rmkl.png'),
    '474-4RMKR': require('../assets/images/products/474-4rmkr.png'),
    '474-6RMKL': require('../assets/images/products/474-6rmkl.png'),
    '474-6RMKR': require('../assets/images/products/474-6rmkr.png'),
    '474-BLND1': require('../assets/images/products/474-blnd1.png'),
    '474-BRACKETL': require('../assets/images/products/474-bracketl.png'),
    '474-BRACKETR': require('../assets/images/products/474-bracketr.png'),
    '474-BRACKET-LL': require('../assets/images/products/474-bracket-ll.png'),
    '474-BRACKET-LR': require('../assets/images/products/474-bracket-lr.png'),
    '474-PSU2': require('../assets/images/products/474-psu2.png'),
    '474-PSU4': require('../assets/images/products/474-psu4.png'),
    '474-PSU6': require('../assets/images/products/474-psu6.png'),
    '474-VESA2': require('../assets/images/products/474-vesa2.png'),
    '474-VESA2N': require('../assets/images/products/474-vesa2n.png'),
    '474-VPLATEL': require('../assets/images/products/474-vplatel.png'),
    '474-VPLATER': require('../assets/images/products/474-vplater.png'),
    '474-VSNAPL': require('../assets/images/products/474-vsnapl.png'),
    '474-VSNAPR': require('../assets/images/products/474-vsnapr.png'),
    'IP-R481-BUHCL': require('../assets/images/products/ip-r481-buhcl.png'),
    'IP-R481-BUHSL': require('../assets/images/products/ip-r481-buhsl.png'),
    'IP-R483-B2HC': require('../assets/images/products/ip-r483-b2hc.png'),
    'IP-R483-B2HS': require('../assets/images/products/ip-r483-b2hs.png'),
    'L474-BSHC': require('../assets/images/products/l474-bshc.png'),
    'L474-BSHC-MAR': require('../assets/images/products/l474-bshc-mar.png'),
    'L474-BSHCR': require('../assets/images/products/l474-bshcr.png'),
    'L474-BSHCR-MAR': require('../assets/images/products/l474-bshcr-mar.png'),
    'L474-BSHCV': require('../assets/images/products/l474-bshcv.png'),
    'L474-BSHS': require('../assets/images/products/l474-bshs.png'),
    'L474-BSHSR': require('../assets/images/products/l474-bshsr.png'),
    'L474-BSHSV': require('../assets/images/products/l474-bshsv.png'),
    'L474-BSHX': require('../assets/images/products/l474-bshx.png'),
    'L474-BSHXV': require('../assets/images/products/l474-bshxv.png'),
    'L474-BVHC': require('../assets/images/products/l474-bvhc.png'),
    'L474-BVHC-MAR': require('../assets/images/products/l474-bvhc-mar.png'),
    'L474-BVHCR': require('../assets/images/products/l474-bvhcr.png'),
    'L474-BVHS': require('../assets/images/products/l474-bvhs.png'),
    'L474-BVHSR': require('../assets/images/products/l474-bvhsr.png'),
    'L481-BHHC': require('../assets/images/products/l481-bhhc.png'),
    'L481-BHHCL': require('../assets/images/products/l481-bhhcl.png'),
    'L481-BHHCLR': require('../assets/images/products/l481-bhhclr.png'),
    'L481-BHHCR': require('../assets/images/products/l481-bhhcr.png'),
    'L481-BHHS': require('../assets/images/products/l481-bhhs.png'),
    'L481-BHHSL': require('../assets/images/products/l481-bhhsl.png'),
    'L481-BHHSLR': require('../assets/images/products/l481-bhhslr.png'),
    'L481-BHHSR': require('../assets/images/products/l481-bhhsr.png'),
    'L481-BHXC': require('../assets/images/products/l481-bhxc.png'),
    'L481-BHXS': require('../assets/images/products/l481-bhxs.png'),
    'L481-BUHCL': require('../assets/images/products/l481-buhcl.png'),
    'L481-BUHCLR': require('../assets/images/products/l481-buhclr.png'),
    'L481-BUHSL': require('../assets/images/products/l481-buhsl.png'),
    'L481-BUHSLR': require('../assets/images/products/l481-buhslr.png'),
    'L482-B2HC': require('../assets/images/products/l482-b2hc.png'),
    'L482-B2HC-MAR': require('../assets/images/products/l482-b2hc-mar.png'),
    'L482-B2HCR': require('../assets/images/products/l482-b2hcr.png'),
    'L482-B2HS': require('../assets/images/products/l482-b2hs.png'),
    'L482-B2HSR': require('../assets/images/products/l482-b2hsr.png'),
    'L482-B2HX': require('../assets/images/products/l482-b2hx.png'),
    'L482-B2HXR': require('../assets/images/products/l482-b2hxr.png'),
    'L482-BDHC': require('../assets/images/products/l482-bdhc.png'),
    'L482-BDHC-MAR': require('../assets/images/products/l482-bdhc-mar.png'),
    'L482-BDHCR': require('../assets/images/products/l482-bdhcr.png'),
    'L482-BDHS': require('../assets/images/products/l482-bdhs.png'),
    'L482-BDHSR': require('../assets/images/products/l482-bdhsr.png'),
    'L482-BDHX': require('../assets/images/products/l482-bdhx.png'),
    'L482-BDHXR': require('../assets/images/products/l482-bdhxr.png'),
    'L483-B2HC': require('../assets/images/products/l483-b2hc.png'),
    'L483-B2HCR': require('../assets/images/products/l483-b2hcr.png'),
    'L483-B2HS': require('../assets/images/products/l483-b2hs.png'),
    'L483-B2HSR': require('../assets/images/products/l483-b2hsr.png'),
    'L483-B2HX': require('../assets/images/products/l483-b2hx.png'),
    'L483-B2HXR': require('../assets/images/products/l483-b2hxr.png'),
    'L483-BDHC': require('../assets/images/products/l483-bdhc.png'),
    'L483-BDHCR': require('../assets/images/products/l483-bdhcr.png'),
    'L483-BDHS': require('../assets/images/products/l483-bdhs.png'),
    'L483-BDHSR': require('../assets/images/products/l483-bdhsr.png'),
    'L483-BPHC': require('../assets/images/products/l483-bphc.png'),
    'L483-BPHC-MAR': require('../assets/images/products/l483-bphc-mar.png'),
    'L483-BPHCR': require('../assets/images/products/l483-bphcr.png'),
    'L483-BPHS': require('../assets/images/products/l483-bphs.png'),
    'L483-BPHSR': require('../assets/images/products/l483-bphsr.png'),
    'L483-BPHX': require('../assets/images/products/l483-bphx.png'),
    'L483-BPHXR': require('../assets/images/products/l483-bphxr.png'),
    'L483-BSHC': require('../assets/images/products/l483-bshc.png'),
    'L483-BSHCR': require('../assets/images/products/l483-bshcr.png'),
    'L483-BSHS': require('../assets/images/products/l483-bshs.png'),
    'L483-BSHSR': require('../assets/images/products/l483-bshsr.png'),
    'L486-BSDC': require('../assets/images/products/l486-bsdc.png'),
    'L486-BSDCR': require('../assets/images/products/l486-bsdcr.png'),
    'L486-BSDS': require('../assets/images/products/l486-bsds.png'),
    'L486-BSDSR': require('../assets/images/products/l486-bsdsr.png'),
    'L488-BIPC': require('../assets/images/products/l488-bipc.png'),
    'L488-BIPCR': require('../assets/images/products/l488-bipcr.png'),
    'L488-BIPEC': require('../assets/images/products/l488-bipec.png'),
    'L488-BIPECR': require('../assets/images/products/l488-bipecr.png'),
    'L488-BIPES': require('../assets/images/products/l488-bipes.png'),
    'L488-BIPESR': require('../assets/images/products/l488-bipesr.png'),
    'L488-BIPS': require('../assets/images/products/l488-bips.png'),
    'L488-BIPSR': require('../assets/images/products/l488-bipsr.png'),
    'L490-BPHC-XS': require('../assets/images/products/l490-bphc-xs.png'),
    'L490-BPHCL-XS': require('../assets/images/products/l490-bphcl-xs.png'),
    'L490-BPHCLR-XS': require('../assets/images/products/l490-bphclr-xs.png'),
    'L490-BPHCR-XS': require('../assets/images/products/l490-bphcr-xs.png'),
    'L490-BPHCX-M': require('../assets/images/products/l490-bphcx-m.png'),
    'L490-BPHCXL': require('../assets/images/products/l490-bphcxl.png'),
    'L490-BPHCXL-R1': require('../assets/images/products/l490-bphcxl-r1.png'),
    'L490-BPHCXLR': require('../assets/images/products/l490-bphcxlr.png'),
    'L490-BPHCXR-M': require('../assets/images/products/l490-bphcxr-m.png'),
    'L490-BPHS-XS': require('../assets/images/products/l490-bphs-xs.png'),
    'L490-BPHSL-XS': require('../assets/images/products/l490-bphsl-xs.png'),
    'L490-BPHSLR-XS': require('../assets/images/products/l490-bphslr-xs.png'),
    'L490-BPHSR-XS': require('../assets/images/products/l490-bphsr-xs.png'),
    'L490-BPHX': require('../assets/images/products/l490-bphx.png'),
    'L490-BPHX-M': require('../assets/images/products/l490-bphx-m.png'),
    'L490-BPHXL': require('../assets/images/products/l490-bphxl.png'),
    'L490-BPHXLR': require('../assets/images/products/l490-bphxlr.png'),
    'L490-BPHXR': require('../assets/images/products/l490-bphxr.png'),
    'L490-BPHXR-M': require('../assets/images/products/l490-bphxr-m.png'),
    'L491-BHHC': require('../assets/images/products/l491-bhhc.png'),
    'L491-BHHCL': require('../assets/images/products/l491-bhhcl.png'),
    'L491-BHHCLR': require('../assets/images/products/l491-bhhclr.png'),
    'L491-BHHCR': require('../assets/images/products/l491-bhhcr.png'),
    'L491-BHHS': require('../assets/images/products/l491-bhhs.png'),
    'L491-BHHSL': require('../assets/images/products/l491-bhhsl.png'),
    'L491-BHHSLR': require('../assets/images/products/l491-bhhslr.png'),
    'L491-BHHSR': require('../assets/images/products/l491-bhhsr.png'),
    'L491-BUHCL': require('../assets/images/products/l491-buhcl.png'),
    'L491-BUHCLR': require('../assets/images/products/l491-buhclr.png'),
    'L491-BUHSL': require('../assets/images/products/l491-buhsl.png'),
    'L491-BUHSLR': require('../assets/images/products/l491-buhslr.png'),
    'L492-B2HC': require('../assets/images/products/l492-b2hc.png'),
    'L492-B2HCR': require('../assets/images/products/l492-b2hcr.png'),
    'L492-B2HS': require('../assets/images/products/l492-b2hs.png'),
    'L492-B2HSR': require('../assets/images/products/l492-b2hsr.png'),
    'L492-B2HX': require('../assets/images/products/l492-b2hx.png'),
    'L492-B2HXR': require('../assets/images/products/l492-b2hxr.png'),
    'L492-BDHC': require('../assets/images/products/l492-bdhc.png'),
    'L492-BDHCR': require('../assets/images/products/l492-bdhcr.png'),
    'L492-BDHS': require('../assets/images/products/l492-bdhs.png'),
    'L492-BDHSR': require('../assets/images/products/l492-bdhsr.png'),
    'L492-BDHX': require('../assets/images/products/l492-bdhx.png'),
    'L492-BDHXR': require('../assets/images/products/l492-bdhxr.png'),
    'L493-B2HC': require('../assets/images/products/l493-b2hc.png'),
    'L493-B2HCR': require('../assets/images/products/l493-b2hcr.png'),
    'L493-B2HS': require('../assets/images/products/l493-b2hs.png'),
    'L493-B2HSR': require('../assets/images/products/l493-b2hsr.png'),
    'L493-B2HX': require('../assets/images/products/l493-b2hx.png'),
    'L493-B2HXR': require('../assets/images/products/l493-b2hxr.png'),
    'L493-BDHC': require('../assets/images/products/l493-bdhc.png'),
    'L493-BDHCR': require('../assets/images/products/l493-bdhcr.png'),
    'L493-BDHS': require('../assets/images/products/l493-bdhs.png'),
    'L493-BDHSR': require('../assets/images/products/l493-bdhsr.png'),
    'L493-BDHX': require('../assets/images/products/l493-bdhx.png'),
    'L493-BDHXR': require('../assets/images/products/l493-bdhxr.png'),
    'L493-BPHC': require('../assets/images/products/l493-bphc.png'),
    'L493-BPHCR': require('../assets/images/products/l493-bphcr.png'),
    'L493-BPHS': require('../assets/images/products/l493-bphs.png'),
    'L493-BPHSR': require('../assets/images/products/l493-bphsr.png'),
    'L493-BPHX': require('../assets/images/products/l493-bphx.png'),
    'L493-BPHXR': require('../assets/images/products/l493-bphxr.png'),
    'L494-BVHC': require('../assets/images/products/l494-bvhc.png'),
    'L494-BVHCR': require('../assets/images/products/l494-bvhcr.png'),
    'L494-BVHS': require('../assets/images/products/l494-bvhs.png'),
    'L494-BVHSR': require('../assets/images/products/l494-bvhsr.png'),
    'L494-BVHX': require('../assets/images/products/l494-bvhx.png'),
    'L495-BHHCXL': require('../assets/images/products/l495-bhhcxl.png'),
    'L495-BHHCXL-R1': require('../assets/images/products/l495-bhhcxl-r1.png'),
    'L495-BHHCXLR': require('../assets/images/products/l495-bhhcxlr.png'),
    'L495-BHHCXLR-R1': require('../assets/images/products/l495-bhhcxlr-r1.png'),
    'L495-BHHXL': require('../assets/images/products/l495-bhhxl.png'),
    'L495-BHHXL-R1': require('../assets/images/products/l495-bhhxl-r1.png'),
    'L495-BHHXLR': require('../assets/images/products/l495-bhhxlr.png'),
    'L495-BHHXLR-R1': require('../assets/images/products/l495-bhhxlr-r1.png'),
    'R474-BSHC': require('../assets/images/products/r474-bshc.png'),
    'R474-BSHC-MAR': require('../assets/images/products/r474-bshc-mar.png'),
    'R474-BSHCR': require('../assets/images/products/r474-bshcr.png'),
    'R474-BSHCR-MAR': require('../assets/images/products/r474-bshcr-mar.png'),
    'R474-BSHS': require('../assets/images/products/r474-bshs.png'),
    'R474-BSHSR': require('../assets/images/products/r474-bshsr.png'),
    'R474-BSHX': require('../assets/images/products/r474-bshx.png'),
    'R474-BVHC': require('../assets/images/products/r474-bvhc.png'),
    'R474-BVHC-MAR': require('../assets/images/products/r474-bvhc-mar.png'),
    'R474-BVHCR': require('../assets/images/products/r474-bvhcr.png'),
    'R474-BVHS': require('../assets/images/products/r474-bvhs.png'),
    'R474-BVHSR': require('../assets/images/products/r474-bvhsr.png'),
    'R481-BHHC': require('../assets/images/products/r481-bhhc.png'),
    'R481-BHHCR': require('../assets/images/products/r481-bhhcr.png'),
    'R481-BHHCW': require('../assets/images/products/r481-bhhcw.png'),
    'R481-BHHCWR': require('../assets/images/products/r481-bhhcwr.png'),
    'R481-BHHS': require('../assets/images/products/r481-bhhs.png'),
    'R481-BHHSR': require('../assets/images/products/r481-bhhsr.png'),
    'R481-BHHSW': require('../assets/images/products/r481-bhhsw.png'),
    'R481-BHHSWR': require('../assets/images/products/r481-bhhswr.png'),
    'R481-BHXC': require('../assets/images/products/r481-bhxc.png'),
    'R481-BHXS': require('../assets/images/products/r481-bhxs.png'),
    'R481-BUHCL': require('../assets/images/products/r481-buhcl.png'),
    'R481-BUHCLR': require('../assets/images/products/r481-buhclr.png'),
    'R481-BUHSL': require('../assets/images/products/r481-buhsl.png'),
    'R481-BUHSLR': require('../assets/images/products/r481-buhslr.png'),
    'R482-B2HC': require('../assets/images/products/r482-b2hc.png'),
    'R482-B2HC-R1': require('../assets/images/products/r482-b2hc-r1.png'),
    'R482-B2HCR': require('../assets/images/products/r482-b2hcr.png'),
    'R482-B2HCR-R1': require('../assets/images/products/r482-b2hcr-r1.png'),
    'R482-B2HS': require('../assets/images/products/r482-b2hs.png'),
    'R482-B2HS-R1': require('../assets/images/products/r482-b2hs-r1.png'),
    'R482-B2HSR': require('../assets/images/products/r482-b2hsr.png'),
    'R482-B2HSR-R1': require('../assets/images/products/r482-b2hsr-r1.png'),
    'R482-B2HX': require('../assets/images/products/r482-b2hx.png'),
    'R482-B2HXR': require('../assets/images/products/r482-b2hxr.png'),
    'R482-BDHC': require('../assets/images/products/r482-bdhc.png'),
    'R482-BDHC-R1': require('../assets/images/products/r482-bdhc-r1.png'),
    'R482-BDHCR': require('../assets/images/products/r482-bdhcr.png'),
    'R482-BDHCR-R1': require('../assets/images/products/r482-bdhcr-r1.png'),
    'R482-BDHS': require('../assets/images/products/r482-bdhs.png'),
    'R482-BDHS-R1': require('../assets/images/products/r482-bdhs-r1.png'),
    'R482-BDHSR': require('../assets/images/products/r482-bdhsr.png'),
    'R482-BDHSR-R1': require('../assets/images/products/r482-bdhsr-r1.png'),
    'R482-BDHX': require('../assets/images/products/r482-bdhx.png'),
    'R482-BDHXR': require('../assets/images/products/r482-bdhxr.png'),
    'R483-B2HC': require('../assets/images/products/r483-b2hc.png'),
    'R483-B2HCR': require('../assets/images/products/r483-b2hcr.png'),
    'R483-B2HS': require('../assets/images/products/r483-b2hs.png'),
    'R483-B2HSR': require('../assets/images/products/r483-b2hsr.png'),
    'R483-B2HX': require('../assets/images/products/r483-b2hx.png'),
    'R483-B2HXR': require('../assets/images/products/r483-b2hxr.png'),
    'R483-BDHC': require('../assets/images/products/r483-bdhc.png'),
    'R483-BDHCR': require('../assets/images/products/r483-bdhcr.png'),
    'R483-BDHS': require('../assets/images/products/r483-bdhs.png'),
    'R483-BDHSR': require('../assets/images/products/r483-bdhsr.png'),
    'R483-BPHC': require('../assets/images/products/r483-bphc.png'),
    'R483-BPHC-MAR': require('../assets/images/products/r483-bphc-mar.png'),
    'R483-BPHCR': require('../assets/images/products/r483-bphcr.png'),
    'R483-BPHS': require('../assets/images/products/r483-bphs.png'),
    'R483-BPHSR': require('../assets/images/products/r483-bphsr.png'),
    'R483-BPHX': require('../assets/images/products/r483-bphx.png'),
    'R483-BPHXR': require('../assets/images/products/r483-bphxr.png'),
    'R483-BSHC': require('../assets/images/products/r483-bshc.png'),
    'R483-BSHCR': require('../assets/images/products/r483-bshcr.png'),
    'R483-BSHS': require('../assets/images/products/r483-bshs.png'),
    'R483-BSHSR': require('../assets/images/products/r483-bshsr.png'),
    'R486-BSDC': require('../assets/images/products/r486-bsdc.png'),
    'R486-BSDCR': require('../assets/images/products/r486-bsdcr.png'),
    'R486-BSDS': require('../assets/images/products/r486-bsds.png'),
    'R486-BSDSR': require('../assets/images/products/r486-bsdsr.png'),
    'R488-BIPC': require('../assets/images/products/r488-bipc.png'),
    'R488-BIPCR': require('../assets/images/products/r488-bipcr.png'),
    'R488-BIPS': require('../assets/images/products/r488-bips.png'),
    'R488-BIPSR': require('../assets/images/products/r488-bipsr.png'),
    'R490-BPHC-XS': require('../assets/images/products/r490-bphc-xs.png'),
    'R490-BPHCL-XS': require('../assets/images/products/r490-bphcl-xs.png'),
    'R490-BPHCLR-XS': require('../assets/images/products/r490-bphclr-xs.png'),
    'R490-BPHCR-XS': require('../assets/images/products/r490-bphcr-xs.png'),
    'R490-BPHCX-M': require('../assets/images/products/r490-bphcx-m.png'),
    'R490-BPHCXL': require('../assets/images/products/r490-bphcxl.png'),
    'R490-BPHCXL-R1': require('../assets/images/products/r490-bphcxl-r1.png'),
    'R490-BPHCXLR': require('../assets/images/products/r490-bphcxlr.png'),
    'R490-BPHCXR-M': require('../assets/images/products/r490-bphcxr-m.png'),
    'R490-BPHS-XS': require('../assets/images/products/r490-bphs-xs.png'),
    'R490-BPHSL-XS': require('../assets/images/products/r490-bphsl-xs.png'),
    'R490-BPHSLR-XS': require('../assets/images/products/r490-bphslr-xs.png'),
    'R490-BPHSR-XS': require('../assets/images/products/r490-bphsr-xs.png'),
    'R490-BPHX': require('../assets/images/products/r490-bphx.png'),
    'R490-BPHX-M': require('../assets/images/products/r490-bphx-m.png'),
    'R490-BPHXL': require('../assets/images/products/r490-bphxl.png'),
    'R490-BPHXLR': require('../assets/images/products/r490-bphxlr.png'),
    'R490-BPHXR': require('../assets/images/products/r490-bphxr.png'),
    'R490-BPHXR-M': require('../assets/images/products/r490-bphxr-m.png'),
    'R491-BUHCL': require('../assets/images/products/r491-buhcl.png'),
    'R491-BUHCLR': require('../assets/images/products/r491-buhclr.png'),
    'R491-BUHSL': require('../assets/images/products/r491-buhsl.png'),
    'R491-BUHSLR': require('../assets/images/products/r491-buhslr.png'),
    'R492-BDHC': require('../assets/images/products/r492-bdhc.png'),
    'R492-BDHCR': require('../assets/images/products/r492-bdhcr.png'),
    'R492-BDHS': require('../assets/images/products/r492-bdhs.png'),
    'R492-BDHSR': require('../assets/images/products/r492-bdhsr.png'),
    'R492-BDHX': require('../assets/images/products/r492-bdhx.png'),
    'R492-BDHXR': require('../assets/images/products/r492-bdhxr.png'),
    'R493-B2HC': require('../assets/images/products/r493-b2hc.png'),
    'R493-B2HCR': require('../assets/images/products/r493-b2hcr.png'),
    'R493-B2HS': require('../assets/images/products/r493-b2hs.png'),
    'R493-B2HSR': require('../assets/images/products/r493-b2hsr.png'),
    'R493-B2HX': require('../assets/images/products/r493-b2hx.png'),
    'R493-B2HXR': require('../assets/images/products/r493-b2hxr.png'),
    'R493-BDHC': require('../assets/images/products/r493-bdhc.png'),
    'R493-BDHCR': require('../assets/images/products/r493-bdhcr.png'),
    'R493-BDHS': require('../assets/images/products/r493-bdhs.png'),
    'R493-BDHSR': require('../assets/images/products/r493-bdhsr.png'),
    'R493-BDHX': require('../assets/images/products/r493-bdhx.png'),
    'R493-BDHXR': require('../assets/images/products/r493-bdhxr.png'),
    'R493-BPHC': require('../assets/images/products/r493-bphc.png'),
    'R493-BPHCR': require('../assets/images/products/r493-bphcr.png'),
    'R493-BPHS': require('../assets/images/products/r493-bphs.png'),
    'R493-BPHSR': require('../assets/images/products/r493-bphsr.png'),
    'R493-BPHX': require('../assets/images/products/r493-bphx.png'),
    'R493-BPHXR': require('../assets/images/products/r493-bphxr.png'),
    'R495-BHHCXL': require('../assets/images/products/r495-bhhcxl.png'),
    'R495-BHHCXL-R1': require('../assets/images/products/r495-bhhcxl-r1.png'),
    'R495-BHHCXLR': require('../assets/images/products/r495-bhhcxlr.png'),
    'R495-BHHCXLR-R1': require('../assets/images/products/r495-bhhcxlr-r1.png'),
    'R495-BHHXL': require('../assets/images/products/r495-bhhxl.png'),
    'R495-BHHXL-R1': require('../assets/images/products/r495-bhhxl-r1.png'),
    'R495-BHHXLR': require('../assets/images/products/r495-bhhxlr.png'),
    'R495-BHHXLR-R1': require('../assets/images/products/r495-bhhxlr-r1.png'),
    'L474-BAE': require('../assets/images/products/l474-bae.png'),
    'L474-BAE-MAR': require('../assets/images/products/l474-bae-mar.png'),
    'L474-BAE2': require('../assets/images/products/l474-bae2.png'),
    'L474-BAH': require('../assets/images/products/l474-bah.png'),
    'L474-BAP': require('../assets/images/products/l474-bap.png'),
    'L474-BAX': require('../assets/images/products/l474-bax.png'),
    'L474-BAX-MAR': require('../assets/images/products/l474-bax-mar.png'),
    'L474-BB2E2': require('../assets/images/products/l474-bb2e2.png'),
    'L474-BB2X': require('../assets/images/products/l474-bb2x.png'),
    'L474-BBE2': require('../assets/images/products/l474-bbe2.png'),
    'L474-BBX': require('../assets/images/products/l474-bbx.png'),
    'L474-BDA': require('../assets/images/products/l474-bda.png'),
    'L474-BDD': require('../assets/images/products/l474-bdd.png'),
    'L474-BDE': require('../assets/images/products/l474-bde.png'),
    'L474-BDE2': require('../assets/images/products/l474-bde2.png'),
    'L474-BDH': require('../assets/images/products/l474-bdh.png'),
    'L474-BDX': require('../assets/images/products/l474-bdx.png'),
    'L474-BRE': require('../assets/images/products/l474-bre.png'),
    'L474-BRE2': require('../assets/images/products/l474-bre2.png'),
    'L474-BRX': require('../assets/images/products/l474-brx.png'),
    'L474-BSE': require('../assets/images/products/l474-bse.png'),
    'L474-BSE2': require('../assets/images/products/l474-bse2.png'),
    'L474-BSH': require('../assets/images/products/l474-bsh.png'),
    'L474-BSS': require('../assets/images/products/l474-bss.png'),
    'L474-BSX': require('../assets/images/products/l474-bsx.png'),
    'L474-BXE': require('../assets/images/products/l474-bxe.png'),
    'L474-BXE-MAR': require('../assets/images/products/l474-bxe-mar.png'),
    'L474-BXE2': require('../assets/images/products/l474-bxe2.png'),
    'L474-BXH': require('../assets/images/products/l474-bxh.png'),
    'L474-BXH-MAR': require('../assets/images/products/l474-bxh-mar.png'),
    'R474-BAE': require('../assets/images/products/r474-bae.png'),
    'R474-BAE-MAR': require('../assets/images/products/r474-bae-mar.png'),
    'R474-BAE2': require('../assets/images/products/r474-bae2.png'),
    'R474-BAH': require('../assets/images/products/r474-bah.png'),
    'R474-BAP': require('../assets/images/products/r474-bap.png'),
    'R474-BAX': require('../assets/images/products/r474-bax.png'),
    'R474-BAX-MAR': require('../assets/images/products/r474-bax-mar.png'),
    'R474-BB2E2': require('../assets/images/products/r474-bb2e2.png'),
    'R474-BB2X': require('../assets/images/products/r474-bb2x.png'),
    'R474-BBE2': require('../assets/images/products/r474-bbe2.png'),
    'R474-BBX': require('../assets/images/products/r474-bbx.png'),
    'R474-BDA': require('../assets/images/products/r474-bda.png'),
    'R474-BDD': require('../assets/images/products/r474-bdd.png'),
    'R474-BDE': require('../assets/images/products/r474-bde.png'),
    'R474-BDE2': require('../assets/images/products/r474-bde2.png'),
    'R474-BDH': require('../assets/images/products/r474-bdh.png'),
    'R474-BDX': require('../assets/images/products/r474-bdx.png'),
    'R474-BGA': require('../assets/images/products/r474-bga.png'),
    'R474-BGE2': require('../assets/images/products/r474-bge2.png'),
    'R474-BGX': require('../assets/images/products/r474-bgx.png'),
    'R474-BRE': require('../assets/images/products/r474-bre.png'),
    'R474-BRE2': require('../assets/images/products/r474-bre2.png'),
    'R474-BRX': require('../assets/images/products/r474-brx.png'),
    'R474-BSE': require('../assets/images/products/r474-bse.png'),
    'R474-BSE2': require('../assets/images/products/r474-bse2.png'),
    'R474-BSH': require('../assets/images/products/r474-bsh.png'),
    'R474-BSS': require('../assets/images/products/r474-bss.png'),
    'R474-BSX': require('../assets/images/products/r474-bsx.png'),
    'R474-BXE': require('../assets/images/products/r474-bxe.png'),
    'R474-BXE-MAR': require('../assets/images/products/r474-bxe-mar.png'),
    'R474-BXE2': require('../assets/images/products/r474-bxe2.png'),
    'R474-BXH': require('../assets/images/products/r474-bxh.png'),
    'R474-BXH-MAR': require('../assets/images/products/r474-bxh-mar.png'),
    'R474-PUSH': require('../assets/images/products/r474-push.png'),
    'L474-BXUC': require('../assets/images/products/l474-bxuc.png'),
    'L474-BXUS': require('../assets/images/products/l474-bxus.png'),
    'R474-BXUC': require('../assets/images/products/r474-bxuc.png'),
    'R474-BXUS': require('../assets/images/products/r474-bxus.png'),
    '474-MODFAN': require('../assets/images/products/474-modfan.png'),
    '474-SNMP': require('../assets/images/products/474-snmp.png'),
    '474-SNMPV3': require('../assets/images/products/474-snmpv3.png'),
    '474-SNMPv3-R1': require('../assets/images/products/474-snmpv3-r1.png'),
    '485-BC': require('../assets/images/products/485-bc.png'),
    '485-BCC': require('../assets/images/products/485-bcc.png'),
    '485-BS': require('../assets/images/products/485-bs.png'),
    '485-BSS': require('../assets/images/products/485-bss.png'),
    '485-BX': require('../assets/images/products/485-bx.png'),
    '485-BXX': require('../assets/images/products/485-bxx.png'),
    'B470-CW4': require('../assets/images/products/b470-cw4.png'),
    'B470-CW8': require('../assets/images/products/b470-cw8.png'),
    'B476-4U4T': require('../assets/images/products/b476-4u4t.png'),
    'B480-08C': require('../assets/images/products/b480-08c.png'),
    'B480-08C-MAR': require('../assets/images/products/b480-08c-mar.png'),
    'B480-08CP': require('../assets/images/products/b480-08cp.png'),
    'B480-08F': require('../assets/images/products/b480-08f.png'),
    'B480-08U': require('../assets/images/products/b480-08u.png'),
    'B480-08X': require('../assets/images/products/b480-08x.png'),
    'C474-BBNC': require('../assets/images/products/c474-bbnc.png'),
    'C474-BSDI': require('../assets/images/products/c474-bsdi.png'),
    'C474-BVGA': require('../assets/images/products/c474-bvga.png'),
    'C474-BVID': require('../assets/images/products/c474-bvid.png'),
    'DPS41-B': require('../assets/images/products/dps41-b.png'),
    'DPS41A-B': require('../assets/images/products/dps41a-b.png'),
    'R488-BIPHHL': require('../assets/images/products/r488-biphhl.png'),
    'L474-1S4C': require('../assets/images/products/l474-1s4c.png'),
    'L474-1SEC': require('../assets/images/products/l474-1sec.png'),
    'L474-1SECA': require('../assets/images/products/l474-1seca.png'),
    'L474-1SHC': require('../assets/images/products/l474-1shc.png'),
    'L474-1SHCA': require('../assets/images/products/l474-1shca.png'),
    'L474-1SHCR': require('../assets/images/products/l474-1shcr.png'),
    'L474-1VHC': require('../assets/images/products/l474-1vhc.png'),
    'L477-1S4CA': require('../assets/images/products/l477-1s4ca.png'),
    'L477-1S4SA': require('../assets/images/products/l477-1s4sa.png'),
    'L477-1S4XA': require('../assets/images/products/l477-1s4xa.png'),
    'L477-1SECA': require('../assets/images/products/l477-1seca.png'),
    'L477-1SESA': require('../assets/images/products/l477-1sesa.png'),
    'L477-1SEXA': require('../assets/images/products/l477-1sexa.png'),
    'L477-1SHC': require('../assets/images/products/l477-1shc.png'),
    'L477-1SHCV': require('../assets/images/products/l477-1shcv.png'),
    'L477-1SHS': require('../assets/images/products/l477-1shs.png'),
    'L477-1SHSV': require('../assets/images/products/l477-1shsv.png'),
    'L477-1SHX': require('../assets/images/products/l477-1shx.png'),
    'L477-1SHXV': require('../assets/images/products/l477-1shxv.png'),
    'L477-1SUC': require('../assets/images/products/l477-1suc.png'),
    'L477-1SUS': require('../assets/images/products/l477-1sus.png'),
    'L477-1SUX': require('../assets/images/products/l477-1sux.png'),
    'L477-2S4C': require('../assets/images/products/l477-2s4c.png'),
    'L477-2S4S': require('../assets/images/products/l477-2s4s.png'),
    'L477-2S4X': require('../assets/images/products/l477-2s4x.png'),
    'L481-1HHC': require('../assets/images/products/l481-1hhc.png'),
    'L481-1HHS': require('../assets/images/products/l481-1hhs.png'),
    'L482-2SHC': require('../assets/images/products/l482-2shc.png'),
    'L483-1PHC': require('../assets/images/products/l483-1phc.png'),
    'L483-1PHCR': require('../assets/images/products/l483-1phcr.png'),
    'R474-1S4C': require('../assets/images/products/r474-1s4c.png'),
    'R474-1SEC': require('../assets/images/products/r474-1sec.png'),
    'R474-1SECA': require('../assets/images/products/r474-1seca.png'),
    'R474-1SHC': require('../assets/images/products/r474-1shc.png'),
    'R474-1SHCA': require('../assets/images/products/r474-1shca.png'),
    'R474-1SHCR': require('../assets/images/products/r474-1shcr.png'),
    'R474-1VHC': require('../assets/images/products/r474-1vhc.png'),
    'R477-1S4CA': require('../assets/images/products/r477-1s4ca.png'),
    'R477-1S4SA': require('../assets/images/products/r477-1s4sa.png'),
    'R477-1S4XA': require('../assets/images/products/r477-1s4xa.png'),
    'R477-1SECA': require('../assets/images/products/r477-1seca.png'),
    'R477-1SESA': require('../assets/images/products/r477-1sesa.png'),
    'R477-1SEXA': require('../assets/images/products/r477-1sexa.png'),
    'R477-1SHC': require('../assets/images/products/r477-1shc.png'),
    'R477-1SHS': require('../assets/images/products/r477-1shs.png'),
    'R477-1SHX': require('../assets/images/products/r477-1shx.png'),
    'R477-1SUC': require('../assets/images/products/r477-1suc.png'),
    'R477-1SUS': require('../assets/images/products/r477-1sus.png'),
    'R477-1SUX': require('../assets/images/products/r477-1sux.png'),
    'R477-2S4C': require('../assets/images/products/r477-2s4c.png'),
    'R477-2S4S': require('../assets/images/products/r477-2s4s.png'),
    'R477-2S4X': require('../assets/images/products/r477-2s4x.png'),
    'R481-1HHC': require('../assets/images/products/r481-1hhc.png'),
    'R481-1HHS': require('../assets/images/products/r481-1hhs.png'),
    'R483-1PHC': require('../assets/images/products/r483-1phc.png'),
    'R483-1PHCR': require('../assets/images/products/r483-1phcr.png'),
    'R483-1PHS': require('../assets/images/products/r483-1phs.png'),
    '260-5G': require('../assets/images/products/260-5g.png'),
    '455-4K': require('../assets/images/products/455-4k.png'),
    '455-5K': require('../assets/images/products/455-5k.png'),
    '455-8G': require('../assets/images/products/455-8g.png'),
    '455-PS': require('../assets/images/products/455-ps.png'),
    '477-VESA': require('../assets/images/products/477-vesa.png'),
    'K487-1PHCRA-N': require('../assets/images/products/k487-1phcra-n.png'),
    'K487-1PHCA-N': require('../assets/images/products/k487-1phca-n.png'),
    'K487-1PHSA-N': require('../assets/images/products/k487-1phsa-n.png'),
    'L487-1PHCRA-N': require('../assets/images/products/l487-1phcra-n.png'),
    'K487-1PHSRA-N': require('../assets/images/products/k487-1phsra-n.png'),
    'L487-1PHCA-N': require('../assets/images/products/l487-1phca-n.png'),
    'L487-1PHSA-N': require('../assets/images/products/l487-1phsa-n.png'),
    'R487-1PHSA-N': require('../assets/images/products/r487-1phsa-n.png'),
    'R487-1PHCA-N': require('../assets/images/products/r487-1phca-n.png'),
    'R487-1PHSRA-N': require('../assets/images/products/r487-1phsra-n.png'),
    'R487-1PHCRA-N': require('../assets/images/products/r487-1phcra-n.png'),
    'L487-1PHSRA-N': require('../assets/images/products/l487-1phsra-n.png'),
    'K417-7M': require('../assets/images/products/k417-7m.png'),
    'K417-7C': require('../assets/images/products/k417-7c.png'),
    'R477-KVSHC-R1': require('../assets/images/products/r477-kvshc-r1.png'),
    'R482-OPHCR': require('../assets/images/products/r482-ophcr.png'),
    'R482-OPHSR': require('../assets/images/products/r482-ophsr.png'),
    'R490-OPECXR': require('../assets/images/products/r490-opecxr.png'),
    'R490-OPEXR': require('../assets/images/products/r490-opexr.png'),
    'L484-8VHCWR': require('../assets/images/products/l484-8vhcwr.png'),
    'K417-6D': require('../assets/images/products/k417-6d.png'),
    'L484-8VESWR': require('../assets/images/products/l484-8veswr.png'),
    'L484-8VECWR': require('../assets/images/products/l484-8vecwr.png'),
    'L484-8VEXWR': require('../assets/images/products/l484-8vexwr.png'),
    'K480-048-R1': require('../assets/images/products/k480-048-r1.png'),
    'K480-080-R1': require('../assets/images/products/k480-080-r1.png'),
    'K480-152-R1': require('../assets/images/products/k480-152-r1.png'),
    'K480-160-R1': require('../assets/images/products/k480-160-r1.png'),
    'K480-288-R1': require('../assets/images/products/k480-288-r1.png'),
    'K480-576-R1': require('../assets/images/products/k480-576-r1.png'),
    'K480-576-R1A': require('../assets/images/products/k480-576-r1a.png'),
    'K480-576S-R1': require('../assets/images/products/k480-576s-r1.png'),
    'K480-576S-R1A': require('../assets/images/products/k480-576s-r1a.png'),
    '480-C8R1': require('../assets/images/products/480-c8r1.png'),
    '480-C8X': require('../assets/images/products/480-c8x.png'),
    '480-C8BDG': require('../assets/images/products/480-c8bdg.png'),
    '480-IPG': require('../assets/images/products/480-ipg.png'),
    '480-GRD-S8-R1': require('../assets/images/products/480-grd-s8-r1.png'),
    '480-S8R1': require('../assets/images/products/480-s8r1.png'),
    '480-S8X': require('../assets/images/products/480-s8x.png'),
    '480-S8BDG': require('../assets/images/products/480-s8bdg.png'),
    '480-UNICOMPACT': require('../assets/images/products/480-unicompact.png'),
    '480-UNI16': require('../assets/images/products/480-uni16.png'),
    '480-CTRL2': require('../assets/images/products/480-ctrl2.png'),
    '480-576-SC': require('../assets/images/products/480-576-sc.png'),
    '459-10S': require('../assets/images/products/459-10s.png'),
    '459-10X': require('../assets/images/products/459-10x.png'),
    '459-1C': require('../assets/images/products/459-1c.png'),
    '459-1S': require('../assets/images/products/459-1s.png'),
    '459-1V': require('../assets/images/products/459-1v.png'),
    '459-3BX': require('../assets/images/products/459-3bx.png'),
    '459-3FX': require('../assets/images/products/459-3fx.png'),
    '459-6M': require('../assets/images/products/459-6m.png'),
    '459-BMB': require('../assets/images/products/459-bmb.png'),
    '480-BLND1': require('../assets/images/products/480-blnd1.png'),
    '480-BLND2': require('../assets/images/products/480-blnd2.png'),
    '480-BLND4': require('../assets/images/products/480-blnd4.png'),
    '480-BLND8': require('../assets/images/products/480-blnd8.png'),
    '480-FAN-048': require('../assets/images/products/480-fan-048.png'),
    '480-FAN-080': require('../assets/images/products/480-fan-080.png'),
    '480-FAN-160': require('../assets/images/products/480-fan-160.png'),
    '480-FAN-160-AR': require('../assets/images/products/480-fan-160-ar.png'),
    '480-FAN-576': require('../assets/images/products/480-fan-576.png'),
    '480-FAN-576-AR': require('../assets/images/products/480-fan-576-ar.png'),
    '480-FLTR-048': require('../assets/images/products/480-fltr-048.png'),
    '480-FLTR-080': require('../assets/images/products/480-fltr-080.png'),
    '480-FLTR-160': require('../assets/images/products/480-fltr-160.png'),
    '480-FLTR-576': require('../assets/images/products/480-fltr-576.png'),
    '480-RED-048': require('../assets/images/products/480-red-048.png'),
    '480-RED-160': require('../assets/images/products/480-red-160.png'),
    '480-RED-288': require('../assets/images/products/480-red-288.png'),
    '480-RED-288-AR': require('../assets/images/products/480-red-288-ar.png'),
    '480-RED-576': require('../assets/images/products/480-red-576.png'),
    '480-RED-576-AR': require('../assets/images/products/480-red-576-ar.png'),
    'K480-08C': require('../assets/images/products/k480-08c.png'),
    'K480-08F': require('../assets/images/products/k480-08f.png'),
    'K480-08U': require('../assets/images/products/k480-08u.png'),
    'K480-08X': require('../assets/images/products/k480-08x.png'),
    'K480-16C': require('../assets/images/products/k480-16c.png'),
    'K480-16F': require('../assets/images/products/k480-16f.png'),
    'K480-16U': require('../assets/images/products/k480-16u.png'),
    'K480-16X': require('../assets/images/products/k480-16x.png'),
    'K480-32C': require('../assets/images/products/k480-32c.png'),
    'K480-32F': require('../assets/images/products/k480-32f.png'),
    'K480-32U': require('../assets/images/products/k480-32u.png'),
    'K480-32X': require('../assets/images/products/k480-32x.png'),
    'K480-48C': require('../assets/images/products/k480-48c.png'),
    'K480-48C16F': require('../assets/images/products/k480-48c16f.png'),
    'K480-48C16U': require('../assets/images/products/k480-48c16u.png'),
    'K480-48C32F': require('../assets/images/products/k480-48c32f.png'),
    'K480-48C32U': require('../assets/images/products/k480-48c32u.png'),
    'K480-48F': require('../assets/images/products/k480-48f.png'),
    'K480-48F16U': require('../assets/images/products/k480-48f16u.png'),
    'K480-48F32U': require('../assets/images/products/k480-48f32u.png'),
    'K480-48U': require('../assets/images/products/k480-48u.png'),
    'K480-48X': require('../assets/images/products/k480-48x.png'),
    'K480-64C': require('../assets/images/products/k480-64c.png'),
    'K480-64F': require('../assets/images/products/k480-64f.png'),
    'K480-64X': require('../assets/images/products/k480-64x.png'),
    'K480-80C': require('../assets/images/products/k480-80c.png'),
    'K480-80F': require('../assets/images/products/k480-80f.png'),
    'K480-80X': require('../assets/images/products/k480-80x.png'),
    'K480-C120': require('../assets/images/products/k480-c120.png'),
    'K480-C120F40': require('../assets/images/products/k480-c120f40.png'),
    'K480-C128': require('../assets/images/products/k480-c128.png'),
    'K480-C144': require('../assets/images/products/k480-c144.png'),
    'K480-C16': require('../assets/images/products/k480-c16.png'),
    'K480-C16-MAR': require('../assets/images/products/k480-c16-mar.png'),
    'K480-C160': require('../assets/images/products/k480-c160.png'),
    'K480-C24': require('../assets/images/products/k480-c24.png'),
    'K480-C24-MAR': require('../assets/images/products/k480-c24-mar.png'),
    'K480-C24F16': require('../assets/images/products/k480-c24f16.png'),
    'K480-C24F16-2RU': require('../assets/images/products/k480-c24f16-2ru.png'),
    'K480-C24F16-4RU': require('../assets/images/products/k480-c24f16-4ru.png'),
    'K480-C24F40': require('../assets/images/products/k480-c24f40.png'),
    'K480-C32': require('../assets/images/products/k480-c32.png'),
    'K480-C32-MAR': require('../assets/images/products/k480-c32-mar.png'),
    'K480-C32G': require('../assets/images/products/k480-c32g.png'),
    'K480-C32G-2RU': require('../assets/images/products/k480-c32g-2ru.png'),
    'K480-C32G-4RU': require('../assets/images/products/k480-c32g-4ru.png'),
    'K480-C40': require('../assets/images/products/k480-c40.png'),
    'K480-C40-2RU': require('../assets/images/products/k480-c40-2ru.png'),
    'K480-C40-4RU': require('../assets/images/products/k480-c40-4ru.png'),
    'K480-C40-MAR': require('../assets/images/products/k480-c40-mar.png'),
    'K480-C40F24': require('../assets/images/products/k480-c40f24.png'),
    'K480-C40F40': require('../assets/images/products/k480-c40f40.png'),
    'K480-C48': require('../assets/images/products/k480-c48.png'),
    'K480-C64': require('../assets/images/products/k480-c64.png'),
    'K480-C80': require('../assets/images/products/k480-c80.png'),
    'K480-C80F40': require('../assets/images/products/k480-c80f40.png'),
    'K480-C80F80': require('../assets/images/products/k480-c80f80.png'),
    'K480-CX120': require('../assets/images/products/k480-cx120.png'),
    'K480-CX120FX40': require('../assets/images/products/k480-cx120fx40.png'),
    'K480-CX128': require('../assets/images/products/k480-cx128.png'),
    'K480-CX144': require('../assets/images/products/k480-cx144.png'),
    'K480-CX16': require('../assets/images/products/k480-cx16.png'),
    'K480-CX160': require('../assets/images/products/k480-cx160.png'),
    'K480-CX24': require('../assets/images/products/k480-cx24.png'),
    'K480-CX24FX16': require('../assets/images/products/k480-cx24fx16.png'),
    'K480-CX24FX16-2RU': require('../assets/images/products/k480-cx24fx16-2ru.png'),
    'K480-CX24FX16-4RU': require('../assets/images/products/k480-cx24fx16-4ru.png'),
    'K480-CX24FX40': require('../assets/images/products/k480-cx24fx40.png'),
    'K480-CX32': require('../assets/images/products/k480-cx32.png'),
    'K480-CX40': require('../assets/images/products/k480-cx40.png'),
    'K480-CX40-2RU': require('../assets/images/products/k480-cx40-2ru.png'),
    'K480-CX40-4RU': require('../assets/images/products/k480-cx40-4ru.png'),
    'K480-CX40FX24': require('../assets/images/products/k480-cx40fx24.png'),
    'K480-CX40FX40': require('../assets/images/products/k480-cx40fx40.png'),
    'K480-CX48': require('../assets/images/products/k480-cx48.png'),
    'K480-CX64': require('../assets/images/products/k480-cx64.png'),
    'K480-CX80': require('../assets/images/products/k480-cx80.png'),
    'K480-CX80FX40': require('../assets/images/products/k480-cx80fx40.png'),
    'K480-CX80FX80': require('../assets/images/products/k480-cx80fx80.png'),
    'K480-F120': require('../assets/images/products/k480-f120.png'),
    'K480-F128': require('../assets/images/products/k480-f128.png'),
    'K480-F144': require('../assets/images/products/k480-f144.png'),
    'K480-F16': require('../assets/images/products/k480-f16.png'),
    'K480-F160': require('../assets/images/products/k480-f160.png'),
    'K480-F24': require('../assets/images/products/k480-f24.png'),
    'K480-F32': require('../assets/images/products/k480-f32.png'),
    'K480-F32G': require('../assets/images/products/k480-f32g.png'),
    'K480-F32G-2RU': require('../assets/images/products/k480-f32g-2ru.png'),
    'K480-F32G-4RU': require('../assets/images/products/k480-f32g-4ru.png'),
    'K480-F40': require('../assets/images/products/k480-f40.png'),
    'K480-F40-2RU': require('../assets/images/products/k480-f40-2ru.png'),
    'K480-F40-4RU': require('../assets/images/products/k480-f40-4ru.png'),
    'K480-F48': require('../assets/images/products/k480-f48.png'),
    'K480-F64': require('../assets/images/products/k480-f64.png'),
    'K480-F80': require('../assets/images/products/k480-f80.png'),
    'K480-FX120': require('../assets/images/products/k480-fx120.png'),
    'K480-FX128': require('../assets/images/products/k480-fx128.png'),
    'K480-FX144': require('../assets/images/products/k480-fx144.png'),
    'K480-FX16': require('../assets/images/products/k480-fx16.png'),
    'K480-FX160': require('../assets/images/products/k480-fx160.png'),
    'K480-FX24': require('../assets/images/products/k480-fx24.png'),
    'K480-FX32': require('../assets/images/products/k480-fx32.png'),
    'K480-FX40': require('../assets/images/products/k480-fx40.png'),
    'K480-FX40-2RU': require('../assets/images/products/k480-fx40-2ru.png'),
    'K480-FX40-4RU': require('../assets/images/products/k480-fx40-4ru.png'),
    'K480-FX48': require('../assets/images/products/k480-fx48.png'),
    'K480-FX64': require('../assets/images/products/k480-fx64.png'),
    'K480-FX80': require('../assets/images/products/k480-fx80.png'),
    'F480-C24S2': require('../assets/images/products/f480-c24s2.png'),
    'F480-C24S3': require('../assets/images/products/f480-c24s3.png'),
    'F480-C24S4': require('../assets/images/products/f480-c24s4.png'),
    'F480-C32GS2': require('../assets/images/products/f480-c32gs2.png'),
    'F480-C32GS3': require('../assets/images/products/f480-c32gs3.png'),
    'F480-C32GS4': require('../assets/images/products/f480-c32gs4.png'),
    'F480-C40S2': require('../assets/images/products/f480-c40s2.png'),
    'F480-C40S3': require('../assets/images/products/f480-c40s3.png'),
    'F480-C40S4': require('../assets/images/products/f480-c40s4.png'),
    'F480-F24S2': require('../assets/images/products/f480-f24s2.png'),
    'F480-F24S3': require('../assets/images/products/f480-f24s3.png'),
    'F480-F24S4': require('../assets/images/products/f480-f24s4.png'),
    'F480-F32GS2': require('../assets/images/products/f480-f32gs2.png'),
    'F480-F32GS3': require('../assets/images/products/f480-f32gs3.png'),
    'F480-F32GS4': require('../assets/images/products/f480-f32gs4.png'),
    'F480-F40S2': require('../assets/images/products/f480-f40s2.png'),
    'F480-F40S3': require('../assets/images/products/f480-f40s3.png'),
    'F480-F40S4': require('../assets/images/products/f480-f40s4.png'),
    'KT-8111L': require('../assets/images/products/kt-8111l.png'),
    'KT-8111R': require('../assets/images/products/kt-8111r.png'),
    'KT-8112L': require('../assets/images/products/kt-8112l.png'),
    'KT-8112R': require('../assets/images/products/kt-8112r.png'),
    'KT-8113L': require('../assets/images/products/kt-8113l.png'),
    'KT-8113R': require('../assets/images/products/kt-8113r.png'),
    'KT-8121L': require('../assets/images/products/kt-8121l.png'),
    'KT-8121R': require('../assets/images/products/kt-8121r.png'),
    'KT-8122L': require('../assets/images/products/kt-8122l.png'),
    'KT-8122R': require('../assets/images/products/kt-8122r.png'),
    'KT-8123L': require('../assets/images/products/kt-8123l.png'),
    'KT-8123R': require('../assets/images/products/kt-8123r.png'),
    'KT-6011L': require('../assets/images/products/kt-6011l.png'),
    'KT-6011R': require('../assets/images/products/kt-6011r.png'),
    'KT-6012L': require('../assets/images/products/kt-6012l.png'),
    'KT-6012R': require('../assets/images/products/kt-6012r.png'),
    'KT-6013L': require('../assets/images/products/kt-6013l.png'),
    'KT-6013R': require('../assets/images/products/kt-6013r.png'),
    'KT-6014IL': require('../assets/images/products/kt-6014il.png'),
    'KT-6014IR': require('../assets/images/products/kt-6014ir.png'),
    'KT-6014L': require('../assets/images/products/kt-6014l.png'),
    'KT-6014R': require('../assets/images/products/kt-6014r.png'),
    'KT-6016IL': require('../assets/images/products/kt-6016il.png'),
    'KT-6016IR': require('../assets/images/products/kt-6016ir.png'),
    'KT-6016L': require('../assets/images/products/kt-6016l.png'),
    'KT-6016LS': require('../assets/images/products/kt-6016ls.png'),
    'KT-6016R': require('../assets/images/products/kt-6016r.png'),
    'KT-6016RS': require('../assets/images/products/kt-6016rs.png'),
    'KT-6021L': require('../assets/images/products/kt-6021l.png'),
    'KT-6021R': require('../assets/images/products/kt-6021r.png'),
    'KT-6022L': require('../assets/images/products/kt-6022l.png'),
    'KT-6022R': require('../assets/images/products/kt-6022r.png'),
    'KT-6023L': require('../assets/images/products/kt-6023l.png'),
    'KT-6023R': require('../assets/images/products/kt-6023r.png'),
    'KT-6024L': require('../assets/images/products/kt-6024l.png'),
    'KT-6024R': require('../assets/images/products/kt-6024r.png'),
    'KT-6026L': require('../assets/images/products/kt-6026l.png'),
    'KT-6026R': require('../assets/images/products/kt-6026r.png'),
    'KT-6035': require('../assets/images/products/kt-6035.png'),
    'KT-6031L': require('../assets/images/products/kt-6031l.png'),
    'KT-6031R': require('../assets/images/products/kt-6031r.png'),
    'KT-6032L': require('../assets/images/products/kt-6032l.png'),
    'KT-6032R': require('../assets/images/products/kt-6032r.png'),
    'KT-6930L': require('../assets/images/products/kt-6930l.png'),
    'KT-6930R': require('../assets/images/products/kt-6930r.png'),
    'KT-6935L': require('../assets/images/products/kt-6935l.png'),
    'KT-6935R': require('../assets/images/products/kt-6935r.png'),
    'KT-6936L': require('../assets/images/products/kt-6936l.png'),
    'KT-6936R': require('../assets/images/products/kt-6936r.png'),
    'KT-6937L': require('../assets/images/products/kt-6937l.png'),
    'KT-6937R': require('../assets/images/products/kt-6937r.png'),
    'KT-6938L': require('../assets/images/products/kt-6938l.png'),
    'KT-6938R': require('../assets/images/products/kt-6938r.png'),
    'KT-6940L': require('../assets/images/products/kt-6940l.png'),
    'KT-6950L': require('../assets/images/products/kt-6950l.png'),
    'KT-6950R': require('../assets/images/products/kt-6950r.png'),
    'KT-6960L': require('../assets/images/products/kt-6960l.png'),
    'KT-6970L': require('../assets/images/products/kt-6970l.png'),
    'KT-6970R': require('../assets/images/products/kt-6970r.png'),
    'KT-6980L': require('../assets/images/products/kt-6980l.png'),
    'KT-6990L': require('../assets/images/products/kt-6990l.png'),
    'KT-6990R': require('../assets/images/products/kt-6990r.png'),
    'KT-6991L': require('../assets/images/products/kt-6991l.png'),
    'KT-6991R': require('../assets/images/products/kt-6991r.png'),
};
export default productImages;
