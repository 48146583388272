//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import ProductCategoryType       from '@constants/ProductCategoryType';
import TestIds                   from '@constants/TestIds';
import * as Configuration        from '@helper/Configuration';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles   from './styles.module.scss';
import Icon     from '../Icon';
import IconType from '../Icon/IconType';

const Component = ({
                       bottomPaneVisible,
                       leftPaneVisible,
                       rightPaneVisible,
                       bottomPaneToggleVisibility,
                       leftPaneToggleVisibility,
                       rightPaneToggleVisibility,
                   }) => {
    const bottomButtonClicked = () => {
        bottomPaneToggleVisibility();
    };
    const leftButtonClicked   = () => {
        leftPaneToggleVisibility();
    };
    const rightButtonClicked  = () => {
        rightPaneToggleVisibility();
    };
    const renderBottomButton  = () => {
        if (!getBottomPaneVisibility()) {
            return null;
        }

        return (
            <span
                data-testId={TestIds.togglePaneButtonBottom}
                className={classNames(
                    styles.splitPaneManagerButton,
                    bottomPaneVisible
                        ? [styles.splitPaneManagerButtonPaneVisible, styles.splitPaneManagerButtonBottomPaneVisible]
                        : null,
                )}
                onClick={bottomButtonClicked}
            >
                <div className={styles.iconWrapper}>
                    <Icon iconType={IconType.panelButtonBottom} />
                </div>
                <span className={styles.splitPaneManagerButtonText}>{I18n.t('panelNameBottom')}</span>
            </span>
        );
    };

    return (
        <div className={styles.splitPaneManager}>
            <span
                data-testId={TestIds.togglePaneButtonLeft}
                className={classNames(
                    styles.splitPaneManagerButton,
                    leftPaneVisible
                        ? [styles.splitPaneManagerButtonPaneVisible, styles.splitPaneManagerButtonLeftPaneVisible]
                        : null,
                )}
                onClick={leftButtonClicked}
            >
                <div className={styles.iconWrapper}>
                    <Icon iconType={IconType.panelButtonLeft} />
                </div>
                <span className={styles.splitPaneManagerButtonText}>{I18n.t('panelNameLeft')}</span>
            </span>
            {renderBottomButton()}
            <div
                data-testId={TestIds.togglePaneButtonRight}
                className={classNames(
                    styles.splitPaneManagerButton,
                    rightPaneVisible
                        ? [styles.splitPaneManagerButtonPaneVisible, styles.splitPaneManagerButtonRightPaneVisible]
                        : null,
                )}
                onClick={rightButtonClicked}
            >
                <div className={styles.iconWrapper}>
                    <Icon iconType={IconType.panelButtonRight} />
                </div>
                <span className={styles.splitPaneManagerButtonText}>{I18n.t('panelNameRight')}</span>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => (
    {
        bottomPaneVisible: state.designerLayout.bottomPaneVisible,
        leftPaneVisible:   state.designerLayout.leftPaneVisible,
        rightPaneVisible:  state.designerLayout.rightPaneVisible,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(DesignerLayoutActions, dispatch);

export const getBottomPaneVisibility = () => {
    const visible = (
        Configuration.getProductCategoryVisibility(ProductCategoryType.extender) ||
        Configuration.getProductCategoryVisibility(ProductCategoryType.matrix) ||
        Configuration.getProductCategoryVisibility(ProductCategoryType.fullIp)
    );

    return visible;
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Component));
