//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { TabPanel }           from 'react-tabs';
import { bindActionCreators } from 'redux';

import CategoryType                    from '@constants/CategoryType';
import ProductSubCategoryType          from '@constants/ProductSubCategoryType';
import Routes                          from '@constants/Routes';
import TabIndices                      from '@constants/TabIndices';
import TestIds                         from '@constants/TestIds';
import Cast                            from '@helper/Cast';
import * as Configuration              from '@helper/Configuration';
import StateHelper                     from '@helper/State';
import { MatrixProductBrowserActions } from '@slices/matrixProductBrowser';

import styles                            from './styles.module.scss';
import MainLevelTabButton                from '../MainLevelTabButton';
import MainLevelTabList                  from '../MainLevelTabList';
import MatrixCompactAccessoryTable       from '../MatrixCompactAccessoryTable';
import MatrixCompactMatrixTable          from '../MatrixCompactMatrixTable';
import MatrixEnterpriseAccessoryTable    from '../MatrixEnterpriseAccessoryTable';
import MatrixEnterpriseChassisTable      from '../MatrixEnterpriseChassisTable';
import MatrixEnterpriseCpuBoardsTable    from '../MatrixEnterpriseCpuBoardsTable';
import MatrixEnterpriseIoBoardsTable     from '../MatrixEnterpriseIoBoardsTable';
import MatrixFlexAccessoryTable          from '../MatrixFlexAccessoryTable';
import MatrixFlexFrontPlateTable         from '../MatrixFlexFrontPlateTable';
import MatrixFlexIoBoardsTable           from '../MatrixFlexIoBoardsTable';
import MatrixFlexMatrixTable             from '../MatrixFlexMatrixTable';
import MatrixFlexMatrixTableCustomDesign from '../MatrixFlexMatrixTableCustomDesign';
import SubLevelTabButton                 from '../SubLevelTabButton';
import SubLevelTabList                   from '../SubLevelTabList';
import tabPaneStyle                      from '../TabPanel/styles.module.scss';
import Tabs                              from '../Tabs';

class Component extends React.Component {
    isSingleOrderContext = () => {
        const singleOrderContext = this.props.pathname === Routes.designerProductSingleOrders;

        return singleOrderContext;
    };

    mainTabChanged = (tabIndex) => {
        this.props.setActiveMainTabIndex({
            activeMainTabIndex: tabIndex,
        });
    };

    // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-847
    productIsSelected = () => {
        return (
            Cast.bool(this.props.selectedProductMeta
                &&
                this.props.selectedProductMeta.categoryType)
            &&
            this.props.pathname.startsWith(Routes.designerProductDetails)
        );
    };

    selectedProductIsMutable = () => {
        const isMutable = _.get(this, 'props.selectedProduct.productData.mutable', false);

        return isMutable;
    };

    compactSubTabChanged = (tabIndex) => {
        this.props.setActiveSubTabCompactIndex({
            activeSubTabCompactIndex: tabIndex,
        });
    };

    flexSubTabChanged = (tabIndex) => {
        this.props.setActiveSubTabFlexIndex({
            activeSubTabFlexIndex: tabIndex,
        });
    };

    enterpriseSubTabChanged = (tabIndex) => {
        this.props.setActiveSubTabEnterpriseIndex({
            activeSubTabEnterpriseIndex: tabIndex,
        });
    };

    renderMatrixTabEnterpriseButton = (productIsSelected, selectedProductSubCategoryType) => {
        if (!Configuration.getTabVisibility(CategoryType.matrix, ProductSubCategoryType.enterprise)) {
            return null;
        }

        return (
            <MainLevelTabButton
                testId={TestIds.mainLevelTabButtonMatrixEnterprise}
                disabled={(
                    productIsSelected &&
                    selectedProductSubCategoryType !== ProductSubCategoryType.enterprise
                )}
                selected={this.props.activeMainTabIndex === TabIndices.mainMatrixTabEnterprise}
                text={I18n.t('matrixTabEnterprise')}
            />
        );
    };

    renderMatrixTabFlexButton = (productIsSelected, selectedProductSubCategoryType) => {
        if (!Configuration.getTabVisibility(CategoryType.matrix, ProductSubCategoryType.flex)) {
            return null;
        }

        return (
            <MainLevelTabButton
                testId={TestIds.mainLevelTabButtonMatrixFlex}
                disabled={(
                    productIsSelected &&
                    selectedProductSubCategoryType !== ProductSubCategoryType.flex
                )}
                selected={this.props.activeMainTabIndex === TabIndices.mainMatrixTabFlex}
                text={I18n.t('matrixTabFlex')}
            />
        );
    };

    renderMatrixTabCompactButton = (productIsSelected, selectedProductSubCategoryType) => {
        if (!Configuration.getTabVisibility(CategoryType.matrix, ProductSubCategoryType.compact)) {
            return null;
        }

        return (
            <MainLevelTabButton
                testId={TestIds.mainLevelTabButtonMatrixCompact}
                disabled={(
                    productIsSelected &&
                    selectedProductSubCategoryType !== ProductSubCategoryType.compact
                )}
                selected={this.props.activeMainTabIndex === TabIndices.mainMatrixTabCompact}
                text={I18n.t('matrixTabCompact')}
            />
        );
    };

    render() {
        const productIsSelected              = this.productIsSelected();
        const selectedProductSubCategoryType = _.get(this.props, 'selectedProduct.productData.subCategoryType', null);

        return (
            <div className={styles.matrixProductBrowser}>
                <Tabs
                    selectedIndex={this.props.activeMainTabIndex}
                    onSelect={this.mainTabChanged}
                >
                    <MainLevelTabList>
                        {this.renderMatrixTabEnterpriseButton(productIsSelected, selectedProductSubCategoryType)}
                        {this.renderMatrixTabFlexButton(productIsSelected, selectedProductSubCategoryType)}
                        {this.renderMatrixTabCompactButton(productIsSelected, selectedProductSubCategoryType)}
                    </MainLevelTabList>
                    {this.renderEnterpriseTabPanel()}
                    {this.renderFlexTabPanel()}
                    {this.renderCompactTabPanel()}
                </Tabs>
            </div>
        );
    }

    renderCompactTabPanel = () => {
        if (!Configuration.getTabVisibility(CategoryType.matrix, ProductSubCategoryType.compact)) {
            return;
        }

        const activeSubTabCompactIndex = this.props.activeSubTabCompactIndex;

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTabCompactIndex}
                    onSelect={this.compactSubTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixCompactChassis}
                            disabled={false}
                            selected={activeSubTabCompactIndex === TabIndices.subTabCompactChassis}
                            text={I18n.t('chassis')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixCompactChassisAccessory}
                            disabled={false}
                            selected={activeSubTabCompactIndex === TabIndices.subTabCompactChassisAccessories}
                            text={I18n.t('accessories')}
                        />
                    </SubLevelTabList>
                    <TabPanel>
                        <MatrixCompactMatrixTable />
                    </TabPanel>
                    <TabPanel>
                        <MatrixCompactAccessoryTable />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    renderEnterpriseTabPanel = () => {
        if (!Configuration.getTabVisibility(CategoryType.matrix, ProductSubCategoryType.enterprise)) {
            return;
        }

        const shouldEnableNonChassisTabs  = this.shouldEnableNonChassisTabs();
        const activeSubTabEnterpriseIndex = (
            shouldEnableNonChassisTabs ?
                this.props.activeSubTabEnterpriseIndex :
                TabIndices.subTabEnterpriseChassis
        );

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTabEnterpriseIndex}
                    onSelect={this.enterpriseSubTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixEnterpriseChassis}
                            disabled={false}
                            selected={activeSubTabEnterpriseIndex === TabIndices.subTabEnterpriseChassis}
                            text={I18n.t('chassis')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixEnterpriseIoBoard}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabEnterpriseIndex === TabIndices.subTabEnterpriseIOBoards}
                            text={I18n.t('ioBoards')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixEnterpriseCpuBoard}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabEnterpriseIndex === TabIndices.subTabEnterpriseCPUBBoards}
                            text={I18n.t('cpuBoards')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixEnterpriseChassisAccessory}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabEnterpriseIndex === TabIndices.subTabEnterpriseAccessories}
                            text={I18n.t('accessories')}
                        />

                    </SubLevelTabList>
                    <TabPanel>
                        <MatrixEnterpriseChassisTable />
                    </TabPanel>
                    <TabPanel>
                        <MatrixEnterpriseIoBoardsTable />
                    </TabPanel>
                    <TabPanel>
                        <MatrixEnterpriseCpuBoardsTable />
                    </TabPanel>
                    <TabPanel>
                        <MatrixEnterpriseAccessoryTable />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    renderFlexTabPanel = () => {
        if (!Configuration.getTabVisibility(CategoryType.matrix, ProductSubCategoryType.flex)) {
            return;
        }

        const activeSubTabFlexIndex    = this.props.activeSubTabFlexIndex;
        const selectedProductIsMutable = this.selectedProductIsMutable();
        const isSingleOrderContext     = this.isSingleOrderContext();

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTabFlexIndex}
                    onSelect={this.flexSubTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixFlexChassis}
                            disabled={false}
                            selected={activeSubTabFlexIndex === TabIndices.subTabFlexChassis}
                            text={I18n.t('matrixTabFlexChassis')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixFlexChassis}
                            disabled={false}
                            selected={activeSubTabFlexIndex === TabIndices.subTabFlexChassisCustomDesign}
                            text={I18n.t('matrixTabFlexChassisCustomDesign')}
                        />
                        {
                            isSingleOrderContext
                                ? (
                                    <SubLevelTabButton
                                        testId={TestIds.subLevelTabButtonMatrixFlexChassis}
                                        disabled={false}
                                        selected={activeSubTabFlexIndex === TabIndices.subTabFlexIOBoards}
                                        text={I18n.t('matrixTabFlexIOBoards')}
                                    />
                                )
                                : null
                        }
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixFlexChassisFrontPlate}
                            disabled={!selectedProductIsMutable}
                            selected={activeSubTabFlexIndex === TabIndices.subTabFlexFrontPlates}
                            text={I18n.t('frontPlates')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonMatrixFlexChassisAccessory}
                            disabled={!selectedProductIsMutable}
                            selected={activeSubTabFlexIndex === TabIndices.subTabFlexAccessories}
                            text={I18n.t('accessories')}
                        />
                    </SubLevelTabList>
                    <TabPanel>
                        <MatrixFlexMatrixTable />
                    </TabPanel>
                    <TabPanel>
                        <MatrixFlexMatrixTableCustomDesign />
                    </TabPanel>
                    {
                        isSingleOrderContext
                            ? (
                                <TabPanel>
                                    <MatrixFlexIoBoardsTable />
                                </TabPanel>
                            )
                            : null
                    }
                    <TabPanel>
                        <MatrixFlexFrontPlateTable />
                    </TabPanel>
                    <TabPanel>
                        <MatrixFlexAccessoryTable />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    /**
     * @returns {boolean|*}
     * @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-327
     */
    shouldEnableNonChassisTabs = () => {
        const productIsSelected    = this.productIsSelected();
        const isSingleOrderContext = this.isSingleOrderContext();

        return (
            isSingleOrderContext ||
            productIsSelected
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        activeMainTabIndex:          _.get(state, 'matrixProductBrowser.activeMainTabIndex', 0),
        activeSubTabCompactIndex:    _.get(state, 'matrixProductBrowser.activeSubTabCompactIndex', 0),
        activeSubTabFlexIndex:       _.get(state, 'matrixProductBrowser.activeSubTabFlexIndex', 0),
        activeSubTabEnterpriseIndex: _.get(state, 'matrixProductBrowser.activeSubTabEnterpriseIndex', 0),
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-327
        pathname:            state.router.location.pathname,
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta: state.activeProject.selectedProduct,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(MatrixProductBrowserActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));
