//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _          from 'lodash';
import BaseSwitch from 'react-switch';

import colors from '@styles/colors.scss';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

class Component extends React.Component {
    render() {
        return (
            <div
                className={styles.switchWrapper}
            >
                <BaseSwitch
                    boxShadow={null}
                    activeBoxShadow={colors.colorPrimary}
                    checked={this.props.checked}
                    height={24}
                    className={styles.switch}
                    offColor={colors.colorGrayLighter3}
                    onColor={colors.colorPrimary}
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

Component.propTypes = {
    checked:  PropTypes.bool,
    onChange: PropTypes.func,
};

Component.defaultProps = {
    checked:  false,
    onChange: _.noop,
};

export default Component;
