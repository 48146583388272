//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';
import * as Configuration       from '@helper/Configuration';
import Connection               from '@helper/Connection';
import Environment              from '@helper/Environment';
import { ExportActions }        from '@slices/export';

import styles                   from './styles.module.scss';
import abas                     from '../../export/abas';
import ErrorBox                 from '../ErrorBox';
import IconType                 from '../Icon/IconType';
import Overlay                  from '../Overlay';
import OverlayType              from '../Overlay/OverlayType';
import TextHeadline             from '../TextHeadline';
import TitleSubTitleButton      from '../TitleSubTitleButton';
import TitleSubTitleButtonTheme from '../TitleSubTitleButton/TitleSubTitleButtonTheme';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    abasButtonPressed = () => {
        abas.exportActiveProject(
            this.props.activeProject,
            this.props.language,
            this.props.customName,
        );

        this.props.exportAbasSuccess();
    };

    checkConnection = () => {
        if (Connection.isOffline()) {
            this.connectionError();

            return false;
        }

        return true;
    };

    connectionError = () => {
        this.setError(I18n.t('exportError1'));
    };

    dracoTeraToolButtonPressed = () => {
        if (this.checkConnection()) {
            // TODO: https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-372
        }
    };

    dracoSystemDesignerButtonPressed = () => {
        if (this.checkConnection()) {
            this.props.downloadExport();
        }
    };

    errorBoxCloseButtonPressed = () => {
        this.setState({
            error: null,
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.export}
                overlayType={OverlayType.dialog}
                title={I18n.t('export')}
            >
                <TextHeadline text={I18n.t('exportDialogText')} />
                {this.renderDracoSystemDesignerButton()}
                {this.renderExportIhseSalesMailbox()}
                {this.renderAbas()}
                {this.renderErrorBox()}
            </Overlay>
        );
    }

    renderAbas = () => {
        if (this.shouldRenderExportAbas()) {
            return (
                <div className={styles.exportOverlayBottomPanel}>
                    <TitleSubTitleButton
                        onClick={this.abasButtonPressed}
                        iconType={IconType.export2}
                        title={I18n.t('exportAbasTitle')}
                        subTitle={I18n.t('exportAbasTitleSubTitle')}
                    />
                </div>
            );
        }

        return null;
    };

    renderDracoSystemDesignerButton = () => {
        const title = this.props.downloadInProgress
            ? I18n.t('downloadInProgress')
            : I18n.t('exportDracoSystemDesignerTitle');

        return (
            <TitleSubTitleButton
                onClick={this.dracoSystemDesignerButtonPressed}
                iconType={IconType.export2}
                disabled={this.props.downloadInProgress}
                theme={TitleSubTitleButtonTheme.orange}
                title={title}
                subTitle={I18n.t('exportDracoSystemDesignerSubTitle')}
            />
        );
    };

    renderErrorBox = () => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-762
        if (this.state.error) {
            return (
                <ErrorBox
                    closeButtonPressed={this.errorBoxCloseButtonPressed}
                    text={this.state.error}
                />
            );
        }

        return null;
    };

    renderExportIhseSalesMailbox = () => {
        if (this.shouldRenderExportIhseSalesMailbox() && this.checkConnection()) {
            return (
                <TitleSubTitleButton
                    route={getPathForOverlayKey(Overlays.salesMailbox)}
                    iconType={IconType.offer}
                    theme={TitleSubTitleButtonTheme.orange}
                    title={I18n.t('exportIhseSalesMailboxTitle')}
                    subTitle={I18n.t('exportIhseSalesMailboxSubTitle')}
                />
            );
        }
    };

    setError = (error) => {
        this.setState({
            error,
        });
    };

    shouldRenderExportAbas = () => {
        const localStorageFlag = 'shouldRenderExportAbas';

        if (
            !localStorage.getItem(localStorageFlag) ||
            !Configuration.isAbasExportEnabled()
        ) {
            return false;
        }

        return true;
    };

    shouldRenderExportIhseSalesMailbox = () => {
        const localStorageFlag = 'shouldRenderExportIhseSalesMailbox';

        if (
            !Configuration.shouldRenderSalesMail() ||
            (
                Environment.isStaging() &&
                !localStorage.getItem(localStorageFlag)
            )
        ) {
            console.warn(`⚠️⚠️⚠️ Warning: Sales mailbox rendering is disabled. ⚠️⚠️⚠️ Type this in the console and reload the page to enable it: localStorage.setItem('${
                localStorageFlag
            }', true);`);

            return false;
        }

        return true;
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        activeProject:                   state.activeProject,
        language:                        state.settings.language,
        cardOverlayMode:                 state.settings.cardOverlayMode,
        cardOverlayModeRenderExtenderId: state.settings.cardOverlayModeRenderExtenderId,
        downloadInProgress:              state.export.downloadInProgress,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ExportActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));
